@use "../../../../../../scss/Variables.scss";

.wizard-feedback-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .content-wrapper {
        display: contents;
    }

    .wizard-feedback__header {
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        position: relative;
        padding: 24px;
        color: #fff;
        font-size: 1rem;

        .feedback__label {
            flex: 1;
        }
        &.--moderate {
            background-color: Variables.$color-neutral-gray-dark;
            color: black;
        }
        &.--incorrect {
            background-color: Variables.$color-error;
        }
        &.--correct {
            background-color: Variables.$color-correct;
        }
        .feedback__correctness-icon {
            margin-right: 12px;
            box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
        }
    }

    .feedback__explanation {
        flex: 1;
        overflow: hidden;
        background-color: #fff;

        .feedback-content--legacy {
            h2,
            .feedback__content {
                padding: 0 24px;
            }
            h2 {
                font-family: "Roboto", sans-serif;
                font-size: 1.1em;
                margin: 0 0 12px 0;
            }
            p {
                margin: 0 0 24px 0;
            }
            img {
                display: inline-block;
                width: 100%;
                max-width: max-content;
                margin-bottom: 24px;
            }
        }

        .feedback-content__content-block {
            padding-inline: 32px;
        }

        .feedback__content--left {
            display: flex;
            img {
                margin-right: 4px;
            }
        }
        .feedback__content--right {
            display: flex;
            img {
                margin-left: 4px;
            }
        }
        .feedback__content--center {
            text-align: center;
        }

        .feedback__explanation__simplebar {
            height: 100%;

            .feedback__explanation-wrapper {
                padding-block: 24px;
            }
        }
    }

    .content-placeholder {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .wizard-feedback-content {
        position: relative;

        .wizard-feedback__header {
            cursor: pointer;
            border-radius: 15px 15px 0 0;
            touch-action: none;

            .feedback__label {
                cursor: pointer;
            }

            .feedback__toggle-button--mobile {
                transition: transform 200ms ease-out;
                transform: rotate(-90deg);

                svg {
                    fill: var(--white);
                }
            }
        }

        .feedback__explanation {
            flex: unset;
            height: 0;
            overflow: hidden;
            padding: 0;
            max-height: 0;
            transition: max-height ease-in-out 200ms;
        }

        &.--open {
            overflow: unset;

            .content-wrapper {
                display: block;
                position: absolute;
                width: 100%;
                bottom: 0;

                .wizard-feedback__header {
                    .feedback__toggle-button--mobile {
                        transform: rotate(90deg);
                    }
                }

                .feedback__explanation {
                    height: auto;
                    padding-top: 24px;
                    max-height: var(--max-toggle-height);

                    .feedback__explanation__simplebar {
                        height: auto;
                        max-height: var(--max-toggle-height);
                    }
                }
            }

            .content-placeholder {
                display: block;
            }
        }
    }
}
