@import "../../../scss/Variables.scss";

.homework-button {
    width: 100%;

    .homework-button__inner-div {
        background-color: rgba(243, 245, 249, 1);
        height: 100%;
        padding: 16px;
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: row;

        .homework-button__label {
            font-size: 1.125rem;
            font-weight: 500;
            text-align: center;
            position: relative;
            margin-left: 17px;
        }
    }

    @media screen and (max-width: 768px) {
        height: 88px;

        .homework-button__inner-div {
            .homework-button__label {
                font-size: 1rem;
                margin-left: 10px;
            }
        }
    }
}
