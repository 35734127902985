.work-mode-card {
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px;
    min-height: 251px;
    background-color: white;
    box-shadow: 0px -6px 18px rgba(0, 0, 0, 0.06),
        0px 6px 18px rgba(0, 0, 0, 0.06);

    &:hover {
        background-color: var(--mode-hover);
    }

    .work-mode-card__inner-div {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;

        .work-mode-card__text {
            margin-bottom: 12px;
            text-align: left;

            .work-mode-card__title {
                font-weight: 500;
                font-size: 1.25rem;
                text-align: left;
            }
            .work-mode-card__description {
                word-break: break-word;
                margin-top: 8px;
            }
        }

        .work-mode-card__illustration {
            height: 158px;
            text-align: center;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            flex: 1 0 auto;

            &.solo-ai {
                background-color: var(--mode-solo-ai-primary-light);
            }

            &.duo {
                background-color: var(--mode-duo-primary-light);
            }

            &.test {
                background-color: var(-mode-test-primary-light);
            }

            &.solo-teacher {
                background-color: var(-mode-teacher-primary-light);
            }

            &.tuto {
                background-color: var(--mode-tuto-primary-light);
            }

            &.workshop {
                background-color: var(--mode-workshop-primary-light);
            }

            .work-mode-card__icon {
                margin: auto;
            }
        }
    }

    &.--disabled {
        background-color: transparent;
        box-shadow: none;
        border: rgba(194, 207, 224, 1) solid 1px;

        .work-mode-card__inner-div {
            .work-mode-card__illustration {
                &.solo-ai,
                &.duo,
                &.test,
                &.solo-teacher,
                &.tuto,
                &.workshop {
                    background-color: transparent;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        min-height: 151px;

        .work-mode-card__inner-div {
            .work-mode-card__text {
                .work-mode-card__title {
                    font-size: 1rem;
                }
                .work-mode-card__description {
                    word-break: break-all;
                    font-size: 0.875rem;
                }
            }

            .work-mode-card__illustration {
                height: 90px;

                .work-mode-card__icon {
                    width: 24px;
                }
            }
        }
    }
}
