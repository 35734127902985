@use "../../../../../../scss/_functions.scss" as fn;
@import "../../../../../../scss/Variables.scss";

.settings{
    display: flex;
    align-items: center;
    justify-content: center;
    width: fn.pxToRem(42);
    height: fn.pxToRem(42);
    .settings__menu-button{
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover, &[aria-expanded]{
           background-color: var(--left-plot-filled);
           border-radius: 100%;
           width: 100%;
           height: 100%; 
        }
        &[aria-expanded]{
            path{
                fill:var(--dark-primary-blue)
            }
        }
    }
}

.settings-options{
    padding: 0;
    border-color: var(--grey-25);
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.24);
    border-radius: 3px;
    width: 300px;
    [role="menuitem"]{
        display: flex;
        align-items: center;
        padding: 16px 24px;
        font-size: 1rem;
        .settings__item-icon{
            margin-left: 8px;
        }
        &:hover, &:focus{
            color: var(--black);
            background-color: var(--layout-lightblue);
        }
    }
}