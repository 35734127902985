@import "../../../../../../scss/Variables.scss";

.activity-label {
    box-sizing: border-box;
    > * {
        box-sizing: border-box;
    }

    display: flex;
    align-items: stretch;

    &:not(:last-child) {
        padding-bottom: 16px;
    }

    .label-icon {
        position: relative;
        width: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            object-fit: contain;
            transform: scale(1.5);
        }

        .circle {
            height: 10px;
            width: 10px;
            background-color: #559fbc;
            border-radius: 50%;

            &.--light {
                background-color: #b3d8e4;
            }
        }

        &:after {
            content: "";
            position: absolute;
            right: 40%;
            top: 0;
            height: calc(100% + 16px);
            width: 1px;
            background-color: #559FBC;
            z-index: -1;
        }
    }

    &:first-child .label-icon:after {
        transform: translateY(50%);
    }
    &:last-child .label-icon:after {
        transform: translateY(-50%);
        height: calc(100% + 8px);
    }

    .label-description {
        width: 100%;
        font-size: 0.9em;
        margin-left: 8px;

        &.--none {
            opacity: 0.33;
        }
        &.--inProgress {
            font-weight: 700;
        }
    }
}
