@use "../../../scss/functions";

$helper-dialog-margin: 24px;

.specimen-helper {
    position: sticky;
    position: -webkit-sticky;
    bottom: 48px;
    margin-inline-start: auto;
    margin-top: auto;

    .specimen-helper__wrapper {
        position: absolute;
        bottom: 0;
        right: 0;
        width: max-content;
    }

    .specimen-helper__dialog {
        box-sizing: border-box;
        min-height: 264px;
        background-color: var(--white);
        box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.12);
        border-radius: 10px;
        padding: 18px;

        .specimen-helper__dialog__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3 {
                font-size: functions.pxToRem(24);
                font-weight: 400;
            }
        }

        .specimen-helper__dialog__description {
            margin-top: 18px;
            white-space: break-spaces;

            a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .specimen-helper {
        margin-inline-end: $helper-dialog-margin;

        .specimen-helper__dialog {
            width: 570px;
        }
    }
}

@media screen and (max-width: 767.99px) {
    .specimen-helper {
        .specimen-helper__dialog {
            width: calc(100vw - $helper-dialog-margin * 2);
        }
    }
}