.video-resource {
    position: relative;
    isolation: isolate;

    video {
        width: 100%;
        position: relative;
    }
    
    .video-resource__controls {
        z-index: 1;
        position: absolute;
        left: 0;
        right: 0;
        $native-controls-height: 70px;
        bottom: $native-controls-height;
        top: $native-controls-height; // so that the buttons are still centered
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        .video-resource__control-btn {
            --bg-color: #333333;
            border-color: var(--bg-color);
            background-color: var(--bg-color);
            color: var(--white);

            &.--dark {
              --bg-color: #191919
            }
        }
    }
}
