$width: 425px;

.ds-module-card {
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  
  background-color: var(--white);
  padding: 16px 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  width: $width;
  border-radius: 12px;
  border: 1px solid var(--medium-grey);
}

.ds-module-title {
  width: 100%;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: left;
  margin-bottom: 40.5px;
}

@media screen and (max-width: 768px) {
  .ds-card-container {
      width: 100%;
  }
}

