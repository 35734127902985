@use "../../../../scss/functions";
@import "../../../../scss/Variables.scss";

.single-classroom {
    flex: 1;
    display: flex;
    flex-direction: column;
    .breadcrumbs {
        margin-left: 8px;
    }

    .single-classroom__header {
        display: flex;
        flex-direction: column;
        margin: 32px 8px 40px 8px;

        .single-classroom__header__code{
            margin: 18px 0 0 0;
            font-weight: 500;
        }
    }

    .single-classroom__tabs{
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    
    .single-classroom__overview {
        width: 100%;
        height: 100%;
    }

}