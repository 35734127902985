@use "../../../../../scss/functions" as fn;

$padding-block: 16px;

.wizard-mascot-zone {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding-top: $padding-block;

    .mascot-icon {
        height: fn.pxToRem(75);
        width: auto;
    }

    > *:last-child:not(.mascot-icon) {
      margin-bottom: $padding-block;
    }
}
