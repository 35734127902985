$background-color: #EAF0FD;
$text-color: #5046b9;

.lock-status-label {
    background-color: $background-color;
    color: $text-color;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 0.625rem;
    padding: 2px 4px;
    width: max-content;
}
