.popover-container{
    padding: 8px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 4px rgba($color: #000000, $alpha: 0.2);
    position: relative;
    .popover__message{
        padding: 8px;
        margin-bottom: 4px;
        border-radius: 5px;
        &.popover__correct{
            background: var(--true-light);
            .popover__result{
                color: var(--true);
            }
        }
        &.popover__incorrect{
            background: var(--false-light);
            .popover__result{
                color: var(--false);
            }
        }
        .popover__result{
            display: flex;
            align-items: center;
            font-size: 1.1rem;
            margin-bottom: 12px;
            span{
                margin-left: 4px;
            }
        }
        .popover__timespent{
            > span{
                display: block;
                &:first-child{
                    font-size: 0.7rem;
                    color: var(--grey);
                    margin-bottom: 4px;
                    text-transform: uppercase;
                }
                &:last-child{
                    font-size: 1rem;
                    font-weight: 600;
                    color: var(--black);
                }
            }
        }
    }
}