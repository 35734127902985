/* Reference for speech bubble css: https://codepen.io/jdniki/pen/isomC */

.emotional-report-widget__container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: sticky;
    bottom: 32px;

    li {
        &:not(:last-child) {
            margin-bottom: 28px;
        }

        button {
            display: flex;
            height: 100%;
            min-height: 80px;

            & > *:not(:last-child) {
                margin-right: 5px;
            }

            p {
                align-self: flex-start;
                width: auto;
                position: relative;
                background: var(--speech-bubble-color);
                border: solid 1px var(--speech-bubble-color);
                -webkit-border-radius: 100px;
                -moz-border-radius: 100px;
                border-radius: 100px;
                -webkit-box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
                -moz-box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
                box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
                font-weight: 700;
                padding: 20px 35px;

                &:after {
                    border-radius: 20px / 5px;
                    content: "";
                    display: block;
                    position: absolute;
                    border: 8px solid transparent;
                    border-bottom-color: var(--speech-bubble-color);
                    bottom: 1px;
                    right: 2px;
                    -webkit-box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
                    -moz-box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
                    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
                    clip-path: inset(
                        0px 0px -5px 0px
                    ); /* Hide drop shadow on all sides except bottom */
                }
            }

            .bot__container {
                align-self: flex-end;

                svg {
                    width: 65px;
                }
            }

            &.button--hide-speech-bubble {
                p {
                    display: none;
                }

                .bot__container {
                    background-color: var(--speech-bubble-color);
                    border-radius: 50%;
                    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
                    width: 50px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        min-width: 65px;
                    }
                }
            }

            &:disabled {
                pointer-events: none;
            }
        }

        &.emotional-report-widget__button--clicked {
            button {
                p {
                    border: 1px solid var(--highlight-color);
                    color: var(--highlight-color);

                    &:before {
                        border-radius: 20px / 5px;
                        content: "";
                        display: block;
                        position: absolute;
                        border: 9px solid transparent;
                        border-bottom-color: var(--highlight-color);
                        bottom: 0;
                        right: 0;
                    }
                }

                &.button--hide-speech-bubble .bot__container {
                    background-color: var(--highlight-color);
                }
            }
        }
    }
}

// Hide speech bubble when screen is smaller
@media (max-width: 1150px) {
    .emotional-report-widget__container li {
        &:not(:last-child) {
            margin-bottom: 0;
        }

        button {
            p {
                display: none;
            }

            .bot__container {
                background-color: ver(--speech-bubble-color);
                border-radius: 50%;
                box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    min-width: 65px;
                }
            }
        }

        &.emotional-report-widget__button--clicked button .bot__container {
            background-color: var(--highlight-color);
        }
    }
}

@media screen and (max-width: 768px) {
    .emotional-report__container--wizard .emotional-report-widget__container {
        flex-direction: row;
        justify-content: center;
        bottom: 3px;

        li:not(:last-child) {
            margin-bottom: 0;
            margin-right: 40px;
        }
    }
}
