@use "../../../../scss/_mixins";
@import "../../../../scss/App.scss";

.adaptive-test-playlist-summary-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .content {
        @include mixins.adaptive-width(840);

        .playlist-summary-icon{
            display: block;
            width: 150px;
            height: auto;
            margin: 10% auto;
        }

        p {
            font-size: 1.2rem;
            padding: 0 0 16px 0;
            text-align: center;
        }

        nav {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 0 auto;

            .playlist-summary__module-list-btn {
                margin-bottom: 16px;
                width: max-content;
            }
        }
    }
}
