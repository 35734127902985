.specimen-modal {
    .ds-modal__content {
        padding: 16px;
        display: flex;
        flex-direction: column;
    }

    .specimen-modal__button {
        margin-top: 48px;
        align-self: flex-end;
    }
}