// COLORS
$color-primary: var(--color-primary);
$color-primary-light: var(--color-primary-light);
$color-secondary: var(--color-secondary);
$color-correct: var(--color-correct);
$color-error: var(--color-incorrect);
$color-editor-one: var(--color-editor-one);
$color-editor-two: var(--color-editor-two);

$progress-background: #EAF4FD;
$progress-bar: #DBECFC;
$inactive-title: #828282;
$inactive-bar: #E5E5E5;
$success-bar: #FFF1E2;
$success-title: #CE800C;

$background-color-primary: #fff;
$background-color-secondary: #f2f6fb;
// 236 240 241
$background-color-gray: #ecf0f1;

$text-color-primary: #2c3e50;
$color-neutral-gray: #eeeeee;
$color-neutral-gray-dark: #f6faf8;
$color-undefined: #979797;

//Dashboard colors
$color-dashboard-primary: #8ac0ec;
$color-dashboard-primary-light: #cce3f7;
$color-dashboard-interaction: #3885eb;

$color-font-default: #444;
