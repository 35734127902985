@use "../../scss/_functions.scss" as fn;

$border-color: var(--grey);
$border-color--disabled: var(--medium-grey);
$border-color--hover-focus: var(--primary-blue);
$border-color--error: var(--false);

$bg-color: var(--layout-grey);
$bg-color--hover-focus: var(--layout-lightblue);
$bg-color--error: var(--false-light);

$font-color--disabled: var(--grey-dark);
$font-color--error: $border-color--error;

$font-color__label: $font-color--disabled;
$font-color__label--hover-focus: $border-color--hover-focus;

$font-color__placeholder: $border-color--disabled;
$font-color__placeholder--disabled: $font-color--disabled;

$border-width--focused: 2px;
$border-width--reduced: 1px;

$input-font-size: fn.pxToRem(18);
$line-height: 1.2;

.ds-input {
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    // Input reset
    input {
        border: none;
        background-image: none;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        margin: 0;

        &:focus {
            outline: none;
        }
    }

    .ds-input__input-container {
        $padding-block: fn.pxToRem(12);
        position: relative;
        padding: $padding-block fn.pxToRem(16);
        background-color: $bg-color;
        border-bottom: $border-width--reduced solid $border-color;
        transition: all ease-in-out 200ms;
        line-height: $line-height;
        height: calc(
            $padding-block * 2 + $line-height * $input-font-size +
                $border-width--focused
        );

        input[type="text"],
        input[type="password"],
        input[type="email"] {
            width: 100%;
            display: block;
            font-size: fn.pxToRem(18);
            transition: all ease-in-out 200ms;
            line-height: $line-height;

            &::placeholder {
                color: $font-color__placeholder;
            }
        }

        &.--disabled {
            border-color: $border-color--disabled;
            cursor: not-allowed;

            input[type="text"],
            input[type="password"],
            input[type="email"] {
                cursor: inherit;
                color: $font-color--disabled;

                &::placeholder {
                    color: $font-color__placeholder--disabled;
                }
            }
        }
        &.--error {
            background-color: $bg-color--error;
            border-color: $border-color--error;
            border-width: $border-width--focused;
        }
        &.--focused {
            outline: 5px auto -webkit-focus-ring-color;
            outline: 5px auto -moz-mac-focusring;

            input[type="text"],
            input[type="password"],
            input[type="email"] {
                &::placeholder {
                    color: transparent;
                }
            }
        }
        &:hover,
        &.--focused {
            &:not(.--disabled):not(.--error) {
                background-color: $bg-color--hover-focus;
                border-color: $border-color--hover-focus;
                border-width: $border-width--focused;
            }
        }

        &.ds-input__input-container--has-button {
            display: flex;
            padding-right: fn.pxToRem(36);

            button.ds-input__reveal-pwd-button {
                width: fn.pxToRem(32);
                height: fn.pxToRem(32);
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);

                &:focus {
                    border: 2px solid var(--black);
                    border-radius: 4px;
                }
            }
        }

        &.ds-input__input-container--has-label {
            $input-font-size: fn.pxToRem(16);
            $padding-bottom: fn.pxToRem(8);
            $padding-top: fn.pxToRem(4);
            $gap: fn.pxToRem(4);
            $label-font-size: fn.pxToRem(12);
            $label-font-size--enlarged: fn.pxToRem(16);

            $total-size: calc(
                $padding-top + $label-font-size * $line-height + $gap +
                    $input-font-size * $line-height + $padding-bottom
            );

            padding-block: calc(
                ($total-size - calc($label-font-size--enlarged * $line-height)) /
                    2 + fn.pxToRem(0.5)
            );
            height: $total-size;

            label {
                color: $font-color__label;
                font-size: $label-font-size--enlarged;
                line-height: $line-height;
                transition: all ease-in-out 200ms;
            }

            input[type="text"],
            input[type="password"],
            input[type="email"] {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
            }

            &:hover,
            &.--focused {
                &:not(.--disabled) label {
                    color: $font-color__label--hover-focus;
                }
            }

            &.--focused,
            &.ds-input__input-container--has-placeholder,
            &:not(.--empty) {
                padding-top: $padding-top;
                padding-bottom: $padding-bottom;

                label {
                    font-size: $label-font-size;
                    margin-bottom: $gap;
                }

                input[type="text"],
                input[type="password"],
                input[type="email"] {
                    font-size: $input-font-size;
                    position: relative;
                    height: auto;
                }
            }
        }
    }

    .ds-input__error-msg {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 1rem;
        color: $font-color--error;
        margin-top: fn.pxToRem(5);

        .ds-input__error-icon {
            position: relative;
            top: fn.pxToRem(0.5);
            width: fn.pxToRem(18);
            height: fn.pxToRem(18);
        }
    }
}
