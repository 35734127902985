.group-card {
    .group-card__module-selector {
        margin-bottom: 50px;
    }

    .group-card__table {
        th:not(:first-child),
        td:not(:first-child) {
            text-align: right;
        }

        th {
          color: var(--grey);
        }

        td {
            font-size: 0.875rem;
        }

        .group-card__value-cell {
            padding-block: 24px;

            > span {
                background-color: var(--left-plot-filled);
                border-radius: 100px;
                padding: 2px 4px;
            }
        }
    }
}
