@use "../../../../scss/_functions.scss" as fn;

.student-list{
    width: 100%;
    margin-top:  42px;
    display: grid;
    grid-template-columns: repeat(auto-fit, fn.pxToRem(165));
    grid-gap: 16px;
    .student-list__student{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 16px;
        border-radius: 4px;
        background-color: var(--white);
        transition: all 0.2s ease-out;
        box-sizing: border-box;
        box-shadow: 2px 4px 8px rgba($color: #000000, $alpha: 0.25);
        &:focus{
            outline: 4px solid  var(--black);
        }
        span{
            &:first-child{
                font-size: fn.pxToRem(60);
            }
            &:last-child{
                font-size: 1rem;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
            }
        }
        &:hover{
            transform: scale(1.05);
        }
    }
}

@media screen and (max-width: fn.pxToRem(600)){
    .student-list{
        grid-template-columns: repeat(1, 1fr);
        .student-list__student{
            width: 100%;
        }
    }
}