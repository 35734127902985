@use "../vars";
@use "../../../scss/functions" as fn;

.forgotten-pwd__container {
    @include vars.auth-container;

    .forgotten-pwd__card {
        @include vars.auth-card;
    }

    .forgotten-pwd {
        display: flex;
        flex-direction: column;
        gap: 40px;

        form {
            display: contents;

            label {
                display: block;
                color: var(--grey-dark);
                margin-bottom: 0.5rem;
            }
        }
    }
    
    .forgotten-password--sent > *:not(:last-child) {
        margin-bottom: 52px;
    }

    .forgotten-pwd__wrong-place {
        @include vars.auth-change-usertype;
    }
}
