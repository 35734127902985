@use "../../../../scss/_functions";

.code-form{
    .code-form__form{
        box-sizing: border-box;
        * {
            box-sizing: border-box;
        }

        display: flex;
        flex-direction: column;
        > div{
            display: grid!important;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: 1fr;
            grid-gap: functions.pxToRem(16);   
            justify-items: center;  
            input{
                text-transform: uppercase;
                text-align: center;
                &:focus{
                    outline: 5px solid var(--dark-primary-blue);
                }
            }   
        }
        a, span{
            display: inline-block;
            margin-top: 20px;
        }
        a{
            color:var(--primary-blue);
            text-decoration: underline;
        }
    }
}

@media screen and (max-width: 50rem){
    .code-form{
        .code-form__form{
            > div{
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}