.auth-error{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    box-sizing: border-box;
    background-color: var(--false-light);
    color: var(--false);
    font-weight: 500;
    span:last-child{
        margin-left: 8px;
    }
}