.solo-module-list{
    width:100%;
    box-sizing: border-box; 
    max-width: 1032px;
    padding: 0 20px;
    margin: 70px auto 0 auto;
    h1{
        margin: 0 0 24px 0;
    }
    h2{
        font-size: 20px;
        margin: 0 0 52px 0;
        font-weight: 400;
    }
    .solo-module-list__modules{
        > article{
            margin: 0 0 12px 0;
        }
    }
}