@use "../../../scss/Variables.scss" as vars;

.playlist-player-container {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    background-color: vars.$background-color-secondary;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .playlist-player__header {
        background-color: vars.$color-primary;
    }

    .playlist-player__content-wrapper {
        flex: 1 1 0;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: stretch;

        .playlist-player__shell,
        .playlist-player__summary {
            flex: 1;
        }
    }
}
