@use "../../../scss/_functions.scss";
@use "../vars";
@use "../../../scss/_functions.scss" as fn;
.student-auth {
    @include vars.auth-container;

    .change-login-method {
        @include vars.auth-change-usertype;
    }

    .student-auth__student-selection {
        h1,
        p {
            color: var(--white);
        }
        h1 {
            font-size: functions.pxToRem(36);
            font-weight: 400;
            margin-bottom: 24px;
        }
        p {
            font-size: functions.pxToRem(24);
        }
    }
}
$padding: 52px;
div.code-form-card__classroom-code,
div.code-form-card__student-code {
    @include vars.auth-container;
    padding: $padding;
    box-sizing: border-box;
    h2 {
        margin-bottom: 40px;
    }
    h3 {
        font-size: functions.pxToRem(18);
        font-weight: 400;
        margin-bottom: 16px;
    }
    .auth-error{
        width: calc(100% + ($padding*2));
        margin-bottom: -$padding;
        margin-top: 24px;
        transform: translateX(calc($padding*(-1)));
    }
}
div.code-form-card__student-code{
    min-width: fn.pxToRem(280);
    padding-top: 0;
}

$padding-mobile: 16px;
@media screen and (max-width: fn.pxToRem(768)) {
    div.code-form-card__classroom-code,
    div.code-form-card__student-code{
        padding: $padding-mobile;
        .auth-error{
            width: calc(100% + ($padding-mobile*2));
            margin-bottom: -$padding-mobile;
            transform: translateX(calc($padding-mobile*(-1)));
        }
    }
}

