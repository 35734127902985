@use "../../../../../scss/functions" as fn;

.wizard-video-tutorial-btn {
    border-radius: 50%;
    background-color: var(--white);
    font-size: fn.pxToRem(40);
    font-weight: 700;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
        0px 1px 3px rgba(0, 0, 0, 0.3);
    color: var(--black);
    width: fn.pxToRem(48);
    height: fn.pxToRem(48);
}

.wizard-video-tutorial-modal {
    $spacing: 32px;

    box-sizing: border-box;
    * {
      box-sizing: border-box;
    }

    box-shadow: 2px 24px 38px rgba(0, 0, 0, 0.14);
    border-radius: 5px;
    margin: 0;
    $p-max-width: 60ch;
    width: calc(2 * $spacing + $p-max-width);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: $spacing;

    .wizard-video-tutorial-dialog {
        padding: 32px;
    }

    header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2 * $spacing;
        
        h2 {
            font-size: fn.pxToRem(24);
            font-weight: 400;
        }
    }

    .tutorial-modal__instruction {
        margin-bottom: $spacing;

        p {
            max-width: $p-max-width;
            font-size: fn.pxToRem(18);
            font-weight: 500;
        }
    }

    .tutorial-modal__video {
        width: 100%;
        display: block;
        margin-bottom: $spacing;
    }

    .tutorial-modal__transcript {
        p {
            margin-bottom: 12px;
        }

        .transcript__btn {
            width: fit-content;
        }
    }
}

@media screen and (max-width: fn.pxToRem(768)) {
    .wizard-video-tutorial-modal {
        $margin: 8px;
        width: calc(100% - (2 * $margin));
        margin-inline: $margin;
        left: 0;
        transform: translateY(-50%);
    }
}
