@import "../../../../scss/Variables.scss";
@import "../Carousel.scss";

.home-content-student {
    width: 100%;
    .secondary-content {
        padding: 100px 0 0 0;
        h2 {
            font-family: "Roboto", sans-serif;
            font-weight: 300;
            font-size: 2.2em;
            padding: 0 0 0 40px;
            border-left: 8px solid $color-secondary;
        }  
        &#section-1 {
            > p{
                margin: 40px 42px 80px 42px;
                font-size: 1.1em;
            }
        }
        &#section-2 {
            .content {
                background-repeat: no-repeat;
                background-size: 200px;
                background-position: 24px 96%;
                position: relative;
                display: flex;

                video {
                    padding: 64px 0 128px 0;
                    flex: 1;
                    display: block;
                    margin: 0 auto;
                    max-width: 80%;
                    min-width: 300px;
                }
                .section-2__background-icon,
                .placeholder {
                    box-sizing: border-box;
                    padding: 16px;
                    align-self: flex-end;
                    width: 20vw;
                    height: 50vh;
                    z-index: -1;
                    display: flex;
                    align-items: flex-end;

                    > img {
                        object-fit: contain;
                    }

                    &.section-2__background-icon--svg img {
                        height: 100%;
                        width: auto;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}
