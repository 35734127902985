@use "../../scss/functions";

$close-button-size: functions.pxToRem(48);
$close-button-size-reduced: functions.pxToRem(16);

.ds-dialog {
    border-radius: 4px;
    background: var(--white);
    position: relative;

    &__header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .ds-dialog__title {
            display: flex;
            align-items: center;
            flex: 1;
            font-size: functions.pxToRem(24);
            font-weight: 400;
        }

    }

    .close-dialog__button {
        width: $close-button-size;
        height: $close-button-size;
        display: flex;
        align-items: center;
        justify-content: center;

        &--absolute {
            width: $close-button-size-reduced;
            height: $close-button-size-reduced;
            position: absolute;
            top: 24px;
            right: 24px;
        }
    }
}

@media (max-width: 50rem) {
    .ds-dialog__content {
        flex-grow: 1;
    }
}
