.new-student-module-card {
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }

    width: 400px;
    background-color: white;
    border-radius: 60px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;

    > *:not(:last-child) {
        margin-bottom: 21px;
    }

    .card__head {
        display: flex;
        gap:16px;
        align-items: center;
        font-size: 1.5rem;
        margin-bottom: 48px;
        > button{
            flex-shrink: 0;
            display: flex;
            align-items: center;
        }
    }

    .card__action {
        min-height: 78px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.125rem;

        > *:not(:last-child) {
            margin-right: 8px;
        }

        .card__error-button {
            text-decoration: underline;
            text-decoration-color: currentColor;
        }
    }

    .card__button {
        padding: 13px 21px;
        border-radius: 30px;
        width: 220px;
        margin: 0 auto;
        
        &:not(.card__button--disabled) {
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
        }
        &.card__button--disabled {
            border: 1px solid var(--medium-grey);
        }

        .button__icon {
            width: 40px;
            height: 40px;
            color: #ff6138;
            border: 4px solid #afd1de;
            border-radius: 50%;
            transform: rotate(-45deg);
            margin-right: 16px;

            > span {
                font-size: 3rem;
                transform: rotate(45deg);
                position: relative;
            }

            &.--in-progress {
                border-right: 4px solid#FF6138;
            }
        }

        .button__label {
            flex: 1;
            text-align: left;
        }
    }

    .card__blazon {
        width: 50px;
        height: 54px;
        position: relative;
        top: 5px;
    }

    .card__completed-medal {
        position: absolute;
        width: 65px;
        height: 105px;
        right: -20px;
        top: -15px;
    }

    &.--module-completed {
        box-shadow: none;
        border: 1px solid #00af3b;
        background-color: #f5fff9;
    }

    &.--inactive {
        box-shadow: none;
        background-color: #F9FBFD;
        justify-content: space-between;

        .card__head .card__title {
            color: rgb(44, 62, 80);
            opacity: 0.24;
            display: flex;
            flex-direction: column;
            width: 100%;

            .card__title__out-of-specimen {
                align-self: center;
            }
        }

        .card__error-picto {
            display: block;
            width: 50%;
            margin: 0 auto;
        }

        .card__locked-picto {
            position: absolute;
            width: 113px;
            top: -45px;
            right: -12px;
        }

        .progress-bar-with-label {
            opacity: 0.3;
        }

        .card__action {
            color: rgba(78, 78, 78, 84);
            font-size: 1.125rem;

            button {
                color: #0383DA;
                font-size: 1.125rem;
                margin-left: 8px;
            }
        }
    }
}
