$toggle-state-txt-color: #373f41;
$chevron-color: rgba(56, 133, 235, 0.37);
$initial-test-description-color: rgba(112, 118, 131, 1);
$hover-chevron-color: #3885eb;

.pr-manager {
    height: 100%;
    display: flex;
    flex-direction: column;

    .manager__item-list {
        // Weirdly had to set those 3 separately otherwise it wouldn't take
        // the flex-basis into account
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0px;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        padding: 24px 32px 0 50px;

        .manager-list-item {
            &:not(:last-child) {
                margin-bottom: 12px;

                &.list-item--module {
                    margin-bottom: 24px;
                }
            }

            &.item-list__initial-test {
                margin-bottom: 32px;

                .initial-test__title {
                    padding-left: 24px;
                }

                .initial-test__description {
                    border: none;
                    font-size: 0.75rem;
                    color: $initial-test-description-color;
                }
            }

            .list-item__description {
                display: flex;
                align-items: center;
                padding-left: 24px;
                > p {
                    flex: 1;
                    text-align: left;
                }

                .list-item__chevron {
                    pointer-events: none;
                    transition: color 0.2s linear;

                    .list-item__chevron-icon {
                        pointer-events: none;
                        color: $chevron-color;
                    }
                }

                &:hover .list-item__chevron .list-item__chevron-icon {
                    color: $hover-chevron-color;
                }
            }

            .list-item__toggle-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100px;
                line-height: 1;
                text-align: center;

                > *:not(:last-child) {
                    margin-bottom: 8px;
                }

                .toggle__state {
                    font-size: 0.75rem;
                    color: $toggle-state-txt-color;
                }

                .toggle__pin {
                    transition: transform 0.2s linear;
                }
            }

            &.--unlocked .list-item__toggle-container:hover .toggle__pin {
                transform: translateX(-4px);
            }

            &.--locked:hover .toggle__pin,
            &.--descendant:hover .toggle__pin {
                transform: translateX(4px);
            }
        }
    }
}
