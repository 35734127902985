@use "../../../../scss/mixins";
@use "../../../../scss/functions" as fn;
@use "../../../../design-system-components/LayerModal/var.scss" as layer-modal;

.test-progression-results {
    width: 100%;
    border-collapse: collapse;
    isolation: isolate;

    th,
    td {
        padding-left: 32px;
        padding-block: 24px;

        &:last-child {
            padding-right: 32px;
        }
    }
    th {
        color: var(--grey-dark);
        font-weight: 400;
        text-align: start;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    thead {
        position: sticky;
        top: 0;
        background-color: var(--white);
        z-index: 1;
    }

    tbody tr {
        &:focus-within {
            @include mixins.focus;
            outline-offset: -2px;
            background-color: var(--layout-lightblue);
        }

        &:hover {
            background-color: var(--light-grey);

            td .test-progression-results__open-exercise {
                visibility: visible;
            }
        }

        td {
            position: relative;

            a.test-progression-results__exercise-link {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;

                &:focus {
                    outline: none;
                }
            }

            &.test-progression-results__exercise-index {
                text-align: center;
            }

            &.test-progression-results__involved-activity,
            .test-progression-results__correctness {
                text-transform: capitalize;
            }

            .test-progression-results__open-exercise {
                visibility: hidden;
                color: var(--primary-blue);
            }
        }
    }

    @media screen and (max-width: layer-modal.$ds-lm--breakpoint-fullsize-max-width) {
        thead {
            @include mixins.visually-hidden;
        }

        .test-progression-results__correctness {
            padding: unset;
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;

            > p {
                @include mixins.visually-hidden;
            }
        }
    }

    @media screen and (max-width: layer-modal.$ds-lm--breakpoint-fullsize-max-width),
        screen and (max-height: layer-modal.$ds-lm--breakpoint-fullsize-max-height) {
        tr {
            border-bottom: 1px solid var(--medium-grey);

            td,
            th {
                padding-left: 8px;

                &:first-child {
                    padding-left: 16px;
                }
                &:last-child {
                    padding-right: 16px;
                }
            }

            td {
                padding-block: 16px;
            }
        }
    }
}
