.FeedbackExplicatif {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 48px;
    gap: 12px;

    position: relative;

    /* White */
    background: #ffffff;
    border-radius: 30px;
    margin-top: 24px;

    @media (max-width: 767px) {
        padding: 24px;
    }
    &.correct {
        border: 3px solid #408d59;
    }

    &.incorrect {
        border: 3px solid #f9ac99;
    }

    h2 {
        font-family: Roboto;
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0.004em;
        text-align: left;
    }
}
