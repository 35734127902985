@import "../../../scss/Variables.scss";

.student-module-card {
    display: flex;
    position: relative;
    width: 280px;
    margin: 0 8px 16px 8px;
    border-radius: 24px;
    background: #ececec;
    flex-direction: column;
    justify-content: space-between;

    .title-container {
        flex: 1;
        display: flex;
        align-items: center;

        .student-path__placeholder,
        .student-path__button {
            width: 31px;
            height: 32px;
            align-self: flex-start;
            margin: 8px 8px 0 0;
        }

        .title {
            display: flex;
            flex-direction: column;
            flex: 1;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 16px;
            color: $inactive-title;
            font-size: 1.3em;
            font-weight: bold;

            &.active {
                color: $color-primary;
            }
        }
    }

    .card-content {
        width: 100%;
        padding-bottom: 16px;
        border-radius: 24px;
        transition: filter 0.5s;
        flex-shrink: 0;

        .bar-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .bar {
                display: flex;
                align-items: center;
                width: 200px;
                height: 48px;
                border-radius: 8px;
                margin: 8px 0;
                background: $inactive-bar;

                &.progress-active {
                    background: $progress-background;
                    color: $color-primary-light;
                }

                .progress-bar {
                    background: $progress-bar;
                    height: 100%;
                    border-radius: inherit;
                    display: flex;
                    align-items: center;

                    .text-content {
                        width: 100%;
                        padding: 8px 8px;
                        color: $color-font-default;
                    }
                }

                &.success-rate-active {
                    background: $success-bar;
                    color: $color-font-default;
                    border-radius: 8px;
                    span {
                        padding: 8px;
                    }
                }

                .text-content {
                    width: 100%;
                    padding: 8px 8px;
                    color: inherit;
                }
            }
        }

        .card-modal {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: filter 0.25s;

            .btn-modal {
                color: #fff;
                padding: 24px 40px;
                background: $color-primary;
                border-radius: 16px;
                opacity: 0;
                transition: opacity 0.25s;
            }
        }

        &:hover {
            cursor: pointer;

            .bar-container {
                filter: blur(4px);
                transition: filter 0.25s;
            }

            .card-modal .btn-modal {
                opacity: 1;
            }
        }
    }
}
