.modules-list {
    padding: 32px 24px;
    .info {
        p {
        }
    }
    h1 {
        margin: 0 0 24px 0;
    }
    > div {
        margin: 0 0 16px 0;
    }
}
