@use "../../../scss/_mixins.scss";
@import "../../../scss/Variables.scss";

.module-list-item {
    background: #fff;
    border: 2px solid $color-primary;
    border-radius: 10px;
    transition: all 0.2s ease-out;
    .header {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 24px 0;
        position: relative;
        button {
            margin: 0 16px 0 24px;
            > span {
                &.icon-container-btn {
                    width: 40px;
                    height: 40px;
                    border-width: 2px;
                    border-color: $color-primary;
                    span {
                        font-size: 2.8em;
                        color: $color-primary;
                        transform-origin: center;
                        transition: all 0.2s ease-out;
                    }
                }
            }
        }
        .title {
            h2 {
                font-size: 1em;
                color: $color-primary;
                font-weight: 700;
            }
            > p {
                display: block;
                font-weight: 400;
                padding-right: 16px;
            }
        }
    }
    ul {
        border-top: 1px solid $color-primary;
        .module-list-item__objective {
            display: flex;
            align-items: center;
            padding: 16px 24px;
            border-bottom: 1px solid #dcdcdc;
            
            &:not(.module-list-item__objective--unavailable) {
                transition: all 0.2s ease-out;

                &:hover {
                    padding-left: 32px;
                    cursor: pointer;
                    background-color: $background-color-secondary;
                }
            }

            &.module-list-item__objective--unavailable {
                .module-list-item__padlock-icon {
                    margin-right: 16px;
                }
            }

            span {
                &.icon-container-btn {
                    margin-right: 16px;
                    margin-left: 0;
                    color: $color-primary;
                    border-width: 2px;
                    @include mixins.width-height(25);
                    background-color: $color-primary;
                    flex: 0 0 25px;
                    span {
                        font-size: 18px;
                        color: #fff;
                    }
                }
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }
    &.opened {
        .header {
            button {
                > span {
                    &.icon-container-btn {
                        span {
                            transform: rotate(0);
                        }
                    }
                }
            }
        }
        ul {
            display: block;
        }
    }
    &.closed {
        .header {
            button {
                > span {
                    &.icon-container-btn {
                        span {
                            transform: rotate(-90deg);
                        }
                    }
                }
            }
        }
        ul {
            display: none;
        }
    }

    .module-list-item__out-of-specimen {
        margin-left: 1ch;
    }
    /* TEMPORARILY not used, needed for visibility status */
    &.disabled, &.module-list-item--unavailable {
        border-color: $color-undefined;
        .header .title h2,
        .header .title > span,
        .header button > span.icon-container-btn span,
        ul li span.icon-container-btn,
        ul li,
        .module-list-item__description,
        .module-list-item__padlock-icon {
            color: $color-undefined;
        }
        .header button > span.icon-container-btn,
        ul {
            border-color: $color-undefined;
        }
        ul li span.icon-container-btn {
            background-color: $color-undefined;
        }
    }

    .module-list-item__objective--unavailable {
        color: $color-undefined;
    }
}
