.ExerciseResultInstruction .additional-instructions {
    margin-top: 8px;
    font-size: smaller;
}
.ExerciseResultGoToNext {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
    margin-bottom: 70px;

    button {
        width: 230px;
        font-size: 18px;
        border-radius: 50px;
        border-style: solid;
        border-width: 2px;
        border-color: var(--chatbot-color-bot-bubble-feedback);
        color: var(--chatbot-color-bot-bubble-feedback);
        background-color: white;
        padding: 16px 32px;
        transition: all 0.25s linear;
    }

    button:hover {
        font-weight: bold;
        background-color: var(--chatbot-color-bot-bubble-feedback);
        color: white;
    }
}

// TO MOVE
.loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0px;
    font-size: 25px;
}

@keyframes ellipsis {
    to {
        width: 20px;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 20px;
    }
}
