@use "./vars";

.ds-btn {
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    width: auto;
    display: flex;
    align-items: center;
    font-size: 1em;
    font-weight: 400;
    border: vars.$border-width solid;
    padding: vars.$padding;
    cursor: pointer;
    &.ds-btn--has-icon {
        .ds-btn__label{
            margin-left: 8px;
        }
    }
    // Alignment
    &.ds-btn--icon-left {
        .ds-icon {
            margin-right: auto;
        }

        .ds-btn__label {
            margin-left: 16px;
        }
    }
    &.ds-btn--icon-right {
        .ds-icon {
            margin-left: auto;
            order: 2;
        }

        .ds-btn__label {
            order: 1;
            margin-right: 16px;
        }
    }
    &.ds-btn--center {
        justify-content: center;
        text-align: center;

        .ds-icon {
            margin-left: 0;
            margin-right: 0;
        }
    }

    // Size
    &.ds-btn--size-large {
        font-size: 1.5em;
        gap: 14px;
    }
    &.ds-btn--size-full {
        box-sizing: border-box;
        text-align: center;
        width: 100%;
    }

    // Appearance
    &.ds-btn--appearance-rectangular {
        border-radius: 4px;
    }
    &.ds-btn--appearance-pill {
        border-radius: 40px;
    }

    // Variants & colors
    .ds-icon svg {
        fill: currentColor;
    }
    &.ds-btn--primary {
        color: var(--white);
        background-color: var(--primary-blue);
        border-color: var(--primary-blue);

        &:not(:disabled):hover {
            background-color: var(--white);
            color: var(--primary-blue);
        }

        &.ds-btn--color-primary {
            background-color: var(--color-primary);
            border-color: var(--color-primary);
            &:not(:disabled):hover {
                color: var(--color-primary);
            }
        }
        &.ds-btn--color-secondary {
            background-color: var(--color-secondary);
            border-color: var(--color-secondary);

            &:not(:disabled):hover {
                color: var(--color-secondary);
            }
        }
    }
    &.ds-btn--secondary,
    &.ds-btn--secondary-light {
        color: var(--primary-blue);
        border-color: currentColor;
        &:not(:disabled):hover {
            background-color: var(--primary-blue);
            color: var(--white);
            border-color: var(--primary-blue);
        }

        &.ds-btn--color-primary {
            color: var(--color-primary);
            &:not(:disabled):hover {
                background-color: var(--color-primary);
                border-color: var(--color-primary);
            }
        }
        &.ds-btn--color-secondary {
            color: var(--color-secondary);
            &:not(:disabled):hover {
                background-color: var(--color-secondary);
                border-color: var(--color-secondary);
            }
        }
    }
    &.ds-btn--secondary-light {
        border-color: var(--medium-grey);

        &.ds-btn--color-primary {
            border-color: var(--color-primary-light);
        }
    }
    &.ds-btn--tertiary {
        border-color: transparent;
        color: var(--primary-blue);
        &:not(:disabled):hover {
            background-color: var(--layout-lightblue);
        }

        &.ds-btn--color-primary {
            color: var(--color-primary);
            &:not(:disabled):hover {
                background-color: var(--color-primary-light);
            }
        }
        &.ds-btn--color-secondary {
            color: var(--color-secondary);
            &:not(:disabled):hover {
                background-color: transparent;
            }
        }
    }
   &.ds-btn--invisible {
       border-color: transparent;
       margin: 0;
       padding: 0;
    }

    // Disabled styles
    &:disabled.ds-btn--disabled-soft {
        opacity: 0.5;
        cursor: not-allowed;
    }
    &:disabled.ds-btn--disabled-hard {
        color: var(--grey-dark);
        border-color: var(--grey-dark);
        background-color: transparent;
        cursor: not-allowed;

        .ds-icon svg {
            fill: var(--dark-grey);
        }
        
        &:hover {
            opacity: 0.5;
        }
    }

    &:focus{
        outline: 2px solid  var(--black);
    }

}
