@use "../../../../../../scss/functions";
@use "../../../../../../design-system-components/Button/vars" as ds-btn;

$separator-color: #736abd;
$description-text-color: rgba(0, 0, 0, 0.56);
$prm-button-color: #006ccb;

.single-classroom__overview {
    &.single-classroom__overview--no-data{
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
        p{
            font-size: functions.pxToRem(20);
        }
    }
    .single-classroom__overview--container {
        display: flex;
        gap: 60px;
        margin-top: 60px;
        
        .single-classroom__overview__modules {
            flex: 2;
            display: flex;
            flex-direction: column;

            .single-classroom__overview__modules__header {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
                min-height: ds-btn.$placeholder-height-with-icon;

                .single-classroom__overview__modules__prm-access-btn {
                    margin-left: 24px;
                }
            }

            .active-modules,
            .inactive-modules {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                gap: 1rem;

                >div {
                    width: 100%;
                    max-width: functions.pxToRem(300);
                }
            }
        }

        .single-classroom__overview__students {
            flex: 1;
            min-width: functions.pxToRem(288);
            max-width: functions.pxToRem(470);
        }

        .separator {
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 32px 0 16px 0;
            isolation: isolate;

            .separator__strike-through {
                width: 100%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                height: 2px;
                background-color: $separator-color;
                z-index: -1;
            }

            .separator__title {
                background: #736abd;
                border-radius: 10px;
                padding: 12px;
                color: white;
            }

            .separator__icon {
                width: 68px;
                margin: 0 30px;
            }
        }

        .no-active-modules__title {
            font-size: 2.25rem;
            text-align: center;
            color: $description-text-color;
            padding: 0 32px;
            margin-bottom: 32px;
        }

        .inactive-modules__description {
            color: $description-text-color;
            text-align: center;
            margin-bottom: 32px;
            padding: 0 32px;
        }

        .no-active-modules__title+.inactive-modules__description {
            margin-bottom: 64px;
        }

        .no-progression {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 65px 0;
            font-size: 48px;
            color: rgba($color: #000000, $alpha: 0.2);
        }
    }
}

@media (max-width: 50rem) {
    .single-classroom__overview {
        margin-top: 24px;
        .single-classroom__overview--container {
            flex-direction: column;
            align-items: stretch;

            .single-classroom__overview__modules {
                .single-classroom__overview__modules__header {
                    flex-wrap: wrap;
                    > *{
                        width: 100%;
                    }
                    .single-classroom__overview__modules__prm-access-btn{
                        margin: 16px 0 0 0;
                    }
                }

                .active-modules,
                .inactive-modules {
                    >div {
                        max-width: 100%;
                    }
                }
            }

            .single-classroom__overview__students {
                max-width: none;
            }
        }
    }
}