$label-color: #3885eb;
$dependency-accent: #db1a1a;
$dependency-background: #fef6f6;

.confirmation-modal__dependency-list {
    summary {
        font-size: 0.875rem;
        color: $label-color;
        text-decoration: underline;
        margin-left: 8px;
        cursor: pointer;
        list-style: none;

        &::-webkit-details-marker {
            display: none;
        }

        > .dependency-list__icon {
            display: inline-block;
            margin-right: 8px;
            position: relative;
            top: 3px;
            transition: transform linear 300ms;
        }
    }

    figure {
        max-height: 0;
        transition: max-height linear 1s;
        border: 1px solid $dependency-accent;
        background-color: $dependency-background;
        padding: 10px;
        width: fit-content;
        margin-top: 20px;
        margin-left: 40px;
        overflow: hidden;

        figcaption {
            font-weight: 700;
            margin-bottom: 8px;
        }

        li {
            line-height: 1.5;
            list-style-type: disc;
            margin-left: 25px;

            &::marker {
                color: $dependency-accent;
            }
        }
    }

    &[open] {
        summary > .dependency-list__icon {
            transform: rotate(0.25turn);
        }

        figure {
            max-height: 200vh;
        }
    }
}

.lock-activity-title {
    display: flex;
    align-items: center;
    gap: 16px;
    
    &::first-letter {
        text-transform: capitalize;
    }
}
