.ds-toggle-div {
  display: block !important;

  .ds-toggle-div__button {
    display: flex;
    cursor: pointer;
    .ds-btn {
      font-size: 1rem;
      border: None
    }
    justify-content: space-between;
  }

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
