@import "../../../../scss/Variables.scss";
@import "../../../../design-system-components/LayerModal/var.scss";

$y-axis-label-height: 2rem;
$activity-legend-container-height: 2rem;
$exercise-legend-container-height: 2rem;
$legend-gap: 5px;
$extra-graph-height: calc(
    $activity-legend-container-height + $exercise-legend-container-height +
        $legend-gap + $legend-gap
);

.ProgressionGraph {
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }

    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    flex: 1;

    > h2 {
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        font-weight: 400;
        font-family: "Roboto", sans-serif;
        > div {
            margin-left: 8px;
        }
    }
    > p {
        padding-top: 12px;
        padding-bottom: 32px;
        font-size: 0.875rem;
        font-weight: 400;
    }

    .legend-container--details {
        display: none;
    }

    .legend-container--details[open] + .progression-content {
        margin-top: 0;
    }

    .progression-content {
        flex: 1 1 calc(200px + $extra-graph-height + $y-axis-label-height);
        max-height: calc(400px + $extra-graph-height + $y-axis-label-height);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        margin-top: 42px;

        .axes {
            position: absolute;
            width: calc(100% - 32px);
            left: 5px;
            top: 0;
            height: calc(100% - $extra-graph-height);
            margin: 0;

            > div {
                position: absolute;
                background-color: var(--medium-grey);
            }
            .axis__x {
                bottom: 0;
                width: 100%;
                height: 1px;
                top: auto;
            }
            .axis__y {
                height: calc(100% - $y-axis-label-height);
                width: 1px;
                left: 0;
                bottom: 0;

                span {
                    position: absolute;
                    top: -30px;
                    left: -5px;
                    font-size: 0.8rem;
                    color: var(--grey);
                }
                &:after {
                    position: absolute;
                    top: -7px;
                    left: -3px;
                    content: "";
                    display: inline-block;
                    height: 0;
                    width: 0;
                    border-right: 4px solid transparent;
                    border-bottom: 7px solid var(--medium-grey);
                    border-left: 4px solid transparent;
                }
            }
        }

        .graph-container {
            flex: 1;
            width: 100%;
            max-height: calc(100% - $y-axis-label-height);
            margin-left: 5px;
            display: flex;
            flex-direction: column;

            .simplebar-content-wrapper {
                display: flex;
            }

            .simplebar-content {
                width: 100%;
                padding-top: 0 !important; // !important to override pesky simplebar styles
                position: relative;
                isolation: isolate;
                align-items: flex-end;
                display: flex;

                .activity-container {
                    height: 100%;
                    padding-inline: 16px;
                    display: flex;
                    flex-direction: column;
                    gap: $legend-gap;
                    justify-content: flex-end;

                    .exercises-container {
                        display: flex;
                        flex: 1;

                        .exercise {
                            display: flex;
                            flex-direction: column;
                            gap: $legend-gap;

                            .bars {
                                height: 100%;
                                display: flex;
                                align-items: flex-end;
                                margin-inline: 16px;
                                position: relative;

                                .bar {
                                    min-height: 40px;
                                    width: 34px;
                                    margin: 0 4px;
                                    border-radius: 5px 5px 0 0;
                                    display: flex;
                                    justify-content: center;

                                    > span {
                                        margin-top: 12px;
                                    }

                                    > button {
                                        top: 0;
                                    }

                                    &.bar--expected {
                                        background-color: var(
                                            --alert-conf-bubble
                                        );
                                        margin-left: 0;
                                        border: 1px solid
                                            var(--alert-conf-default);
                                        box-sizing: border-box;
                                    }

                                    &.bar--current {
                                        border: 2.5px solid
                                            var(--alert-conf-chip-dark);
                                        box-sizing: border-box;
                                        background-color: var(
                                            --alert-conf-default
                                        );
                                        margin-right: 0;
                                        position: relative;

                                        > button{
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            position: absolute;
                                            left: 0;
                                            top: 0;
                                            box-sizing: border-box;
                                            border-radius: 5px 5px 0 0 ;
                                        }

                                        &.bar--positive {
                                            background-color: var(
                                                --alert-sup-default
                                            );
                                        }

                                        &.bar--negative {
                                            background-color: var(
                                                --alert-inf-default
                                            );
                                        }


                                    }
                                }

                                .break-progression {
                                    cursor: pointer;
                                }
                            }

                            .exercise__legend {
                                background-color: var(--alert-conf-bubble);
                                height: $exercise-legend-container-height;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-shrink: 0;

                                a {
                                    text-decoration: underline;
                                }
                                &:hover a {
                                    font-weight: 600;
                                }
                            }

                            &:first-child .bars {
                                margin-left: 0;
                            }
                            &:last-child .bars {
                                margin-right: 0;
                            }
                            &:first-child .exercise__legend {
                                border-radius: 5px 0 0 5px;
                            }
                            &:last-child .exercise__legend {
                                border-radius: 0 5px 5px 0;
                            }
                            &:only-child .exercise__legend {
                                border-radius: 5px;
                            }
                        }
                    }

                    .activity__legend {
                        height: $activity-legend-container-height;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1440px), screen and (max-height: 850px) {
    .ProgressionGraph {
        .legend-container {
            display: none;
        }
        .legend-container--details {
            display: block;
        }
    }
}

@media screen and (max-width: $ds-lm--breakpoint-fullsize-max-width),
    screen and (max-height: $ds-lm--breakpoint-fullsize-max-height) {
    .ProgressionGraph > h2 {
        font-size: 1rem;
    }
}
