.ds-resource-card{
    box-sizing: border-box;
    border: 1px solid transparent;
    header{
        display: flex;
        margin: 0 0 12px 0;
        .icon{
             display: flex;
             align-items: center;
             justify-content: center;
        } 
        .infos{
            margin-inline-start: 12px;
            :first-child{
                font-size: 1rem;
                margin: 0;
            }
            span{
                display: block;
                &:first-child{
                    font-weight: 500;
                }
            }
        }
    }
    .ds-resource-card__image{
        margin: 0 0 12px 0;
    }
    &.--clickable{
        cursor: pointer;
    }
    &.--disabled{
        cursor: default;
        color: var(--grey-dark);
        background: none;
        border-color: var(--grey-25);
        box-shadow: none;
        .icon{
            background-color: white;
        }
    }
}