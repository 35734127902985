@import '../../../../scss/Variables.scss';

.bar-container{
    height: 50px;
    padding: 0 8px;
    margin: 8px 0;
    display: flex;
    overflow: hidden;
    position: relative;
    isolation: isolate;
    border-radius: 10px;
    background-color: var(--color-bar-bg);
    .bar-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
    }
    .label, .value{
        position: relative;
        z-index: 10;
    }
    .label{
        width: 100%;
    }
    .value{
        width: 110px;
        justify-content: right;
    }
    &:first-child{
        margin-top: 0;
    }
    &.progress{
        .value{
            display: flex;
            align-items: center;
            padding: 0 0 0 8px;
            justify-content: right;
            color: var(--color-bar-value);
        }
        .progress{
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            height: 100%;
            background-color: var(--color-bar-progress);
        }
    }
    &.moduleGroups{
        .value{
            color:var(--color-bar-value);
            text-decoration: underline;
            width: 30%;
        }
    }
    &.noData{
        background-color: rgba($color: #000000, $alpha: 0.05);
        .value{
            color: rgba($color: #000000, $alpha: 0.3);
        }
    }
}