.error-modal {
    &__dialog {
        border-radius: 10px;
        padding: 42px 32px 32px 32px;

        &--no-content {
            padding-top: 32px;
        }

        &--notification {
            background-color: rgb(255, 229, 229);
        }
    }

    &__title {
        margin-bottom: 24px;
    }
}
