.circles-container {
    background: #fafcff;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    justify-content: center;
    margin-bottom: 20px;

    .circle {
        display: flex;
        flex-direction: row;
        margin: 5px;
        max-width: 20%;
        flex-wrap: wrap;
        background: #ffffff;
        border-radius: 50%;
        padding: 20px;
        align-self: center;
        justify-content: center;
        margin-right: 30px;
        aspect-ratio: 1;
        align-items: center;

        &.circle-border-1 {
            border: 4px solid #dfedf2;
        }

        &.circle-border-2 {
            border: 4px solid #e8d8e7;
        }

        &.circle-border-3 {
            border: 4px solid #cce8d6;
        }

        &.circle-border-4 {
            border: 4px solid #edb18e;
        }

        &.circle-border-5 {
            border: 4px solid #dcde90;
        }

        &.shadow {
            box-shadow: 0px 0px 12px #9b9a9a;
        }

        .student {
            display: flex;
            color: lightblue;
            margin: 5px;
            font-size: 30px;

            &.student-cluster-1 {
                color: #dfedf2;
            }

            &.student-cluster-2 {
                color: #e8d8e7;
            }

            &.student-cluster-3 {
                color: #cce8d6;
            }

            &.student-cluster-4 {
                color: #edb18e;
            }

            &.student-cluster-5 {
                color: #dcde90;
            }

            &.selected {
                color: #60a4bf;
            }
        }
    }
}
