$breakpoint-tablet: 768px;
@media (max-width: $breakpoint-tablet) {
  .ds-progress-bar .ds-bar-label > .ds-tool-tip {
    display: none;
  }
}

.ds-progress-bar {
  color: #192A3E;
  box-sizing: border-box;
  background-color: var(--layout-lightblue);
  height: 59px;
  padding: 9px 11px;

    .ds-progress-content {
      display: flex;
      justify-content: space-between;
      isolation: isolate;
      position: relative;
      height: 51px;
      box-sizing: border-box;
      left: 0px;
      top: 4px;
      border-radius: 2px;
      justify: space-between;
      font-family: Roboto;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.17;
      letter-spacing: 0.004em;
      overflow: hidden;
    }

    .ds-bar-label {
      display: flex;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
    }

    .ds-bar-label > .ds-tool-tip {
      margin-left: 5px;
    }

    .ds-progress-bar__background {
      position: absolute;
      box-sizing: border-box;
      width: 100%;
      height: 0px;
      border-radius: 9px;
      border-bottom: 5px solid #006CCB17;
      top: 31px;
    }

    .ds-progress-bar__real {
      position: absolute;
      box-sizing: border-box;
      border-bottom: 5px solid var(--primary-blue);
      border-radius: 9px;
      top: 31px;
      height: 0px;
    }

    .ds-progress-bar__estimate {
      position: absolute;
      background-color: rgba(0, 108, 203, 0.3);
      border-radius: 9px;
      top: 26px;
      height: 15px;
      border: 1px solid var(--primary-blue)
    }

  .ds-progress__under {
    position: absolute;
    bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
