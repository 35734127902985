.single-classroom__module-card {
    > div:first-child{
        display: flex;
        h3 {
            font-size: 1rem;
            font-weight: 500;
        }
    
        > button {
            margin-left: auto;
        }
    }
}