@use "../../../../../../design-system-components/Button/vars" as ds-btn;
@use "../../../../../../design-system-components/BorderlessCard/vars" as ds-card;
@use "../../../../../../design-system-components/Table/vars" as ds-table;

.students-overview {
    .students-overview__title {
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: ds-btn.$placeholder-height-with-icon;
    }

    .students-overview__students-table {
        table-layout: fixed;
        width: 100%;
        tr {
            width: 100%;
        }
        td,
        th {
            padding: 0;
            &:first-child{
                width: 70%;
            }
            &:last-child{
                width: 30%;
                padding-left: 8px;
            }
        }
        th{
            padding-bottom: 8px; 
        }
        td{
            padding: 30px 0;
        }
        &.students-overview__students-table--bottom-border tr:last-child {
            border-bottom: ds-table.$ds-t--table-border;
        }
        &.students-overview__students-table--collapse-bottom-padding {
            margin-bottom: 0px - ds-card.$borderless-card-padding;
        }

        .student-overview__student-tooltip {
            width: 100%;

            > button {
                width: 100%;
                text-align: left;
            }

            .student-overview__student-link {
                text-decoration: underline;
                color: var(--font-black);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
            }
        }

        .alert-cell {
           
            padding-block: 12px;

            .alert-cell__no-alert {
                text-transform: uppercase;
                color: var(--font-grey);
                font-size: 0.75rem;
                width: max-content;
            }
        }
    }

    .students-overview__collapse-button {
        width: max-content;
        margin: 6px auto 0 auto;
        margin-bottom: 6px - ds-card.$borderless-card-padding;
        font-weight: 700;

        .ds-icon {
            margin-right: 12px;
        }
    }
}
