@use "../../../scss/_functions.scss";

.select-user-type-container {
    width: 100%;
    max-width: functions.pxToRem(777);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .select-user-type--header {
        color: var(--white);
        h1 {
            font-size: functions.pxToRem(36);
            margin-bottom: functions.pxToRem(42);
        }
    }
    .select-user-type {
        display: flex;
        button {
            width: 370px;
            height: 320px;
            padding: 38px 50px;
            font-size: 1.5rem;
            background-color: var(--white);
            box-shadow: 2px 4px 8px rgba($color: #000000, $alpha: 0.25);
            border-radius: 10px;
            transition: all 0.2s ease-out;
            position: relative;
            &:focus {
                outline-width: 5px;
            }
            .ds-icon {
                margin-bottom: functions.pxToRem(12);
            }
            div {
                text-align: center;
                color: var(--black);
                font-weight: 600;
                position: absolute;
                bottom: 32px;
                transform: translateX(-50%);
                left: 50%;
                width: 100%;
            }
            &:hover {
                transform: scale(1.05);
            }
            &:not(:last-child) {
                margin-right: 37px;
            }
        }
    }
    .select-user-type__help {
        margin-top: 53px;
        color: var(--white);
        .select-user-type__helpInformation {
            position: relative;
            p {
                font-size: functions.pxToRem(24);
            }
            .auth___mascot {
                position: absolute;
                left: -160px;
            }
        }
        .select-user-type__contact {
            margin-top: 53px;
            font-size: 1rem;
            .contact-link {
                color: var(--white);
                text-decoration: underline;
            }

            &:only-child {
                font-size: functions.pxToRem(24);
            }
        }
    }
}
@media (max-width: 50rem) {
    .select-user-type-container {
        .select-user-type {
            flex-direction: column;
            margin-top: 0;
            button {
                margin: auto;
                margin-top: functions.pxToRem(64);
                width: 100%;
            }
        }
    }
}