.progress-bar__container {
  width: 100%;
  background-color: var(--chatbot-color-bot-bubble-opacity-60);
  border-radius: 21px;
  height: 32px;
  overflow: hidden;
  position: relative;

  .progress-bar__completion {
    background-color: var(--chatbot-color-bot-bubble);
    border-radius: inherit;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
}