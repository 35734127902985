@import "../../../../scss/Variables.scss";

.notification-message--with-icon {
    .notification-message--with-icon__icon {
        margin-right: 8px;
    }

    &.--success .notification-message--with-icon__icon {
        color: $color-correct;
    }

    &.--failure .notification-message--with-icon__icon {
        color: $color-error;
    }
}
