@use "../../../../scss/functions" as fn;

.teacher-header {
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }

    padding: 13px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header_close {
        a {
            display: flex;
            align-items: center;

            span {
                color: #fff;

                &:first-child {
                    font-size: 24px;
                }

                &:nth-child(2) {
                    font-size: 1em;
                }
            }
        }
    }
}

@media screen and (max-width: fn.pxToRem(768)) {
    .teacher-header {
        padding: 8px 0;
        height: 56px;
        align-items: flex-start;

        .header_close {
            z-index: 10;

            span:nth-child(2) {
                display: none;
            }
        }
    }
}
