@use "../../../../scss/functions" as fn;

.student-header {
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;

    .student-header__exit {
        // TODO: Sort out color with client variables
        display: flex;
        padding: 8px 24px;
        margin: 0 0 0 16px;
        background-color: #c4c4c450;
        color: white;
        font-size: 18px;
        border-radius: 31px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .exit__icon {
            font-size: 32px;
        }
    }

    .student-header__progression {
        min-width: 50%;

        h2 {
            padding: 0 16px;
            color: white;
            font-family: "Roboto", sans-serif;
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 8px;
            text-align: center;
        }

        .progress-bar__container {
            max-width: 500px;
            width: 100%;
            margin: 0 auto;
        }
    }
}
