@import "_variables";
@import "../../../scss/Variables.scss";

#student-details-container {
    .top-container {
      display: flex;
      align-items: center;
      margin: 0 0 24px 24px;

      > *:not(:last-child) {
        margin-right: 48px;
      }

      .back-btn {
          background-color: #ccc;
          border-radius: 100%;
          width: 24px;
          height: 24px;
          padding: 8px;
      }
    }

    h1 {
        display: flex;
        width: 100%;
    }

    h2 {
        font-family: "Roboto";
        margin-bottom: 16px;
        font-size: 2.25rem;
        font-weight: 300;
    }

    #student-name-container {
        width: 60%;
        margin: 16px 16px;
        padding: 8px 0;
        border-bottom: 4px solid $light-blue;
        display: flex;
        align-items: center;

        #student-name {
            margin: 0 8px;
            font-size: 2.5em;
            color: $primary-student;
        }
    }

    #card-container {
        width: 90%;
        margin: 8px auto;

        .card {
            margin: 48px 16px;

            &.small {
                width: calc(40% - 32px);
            }

            &.medium {
                width: calc(60% - 32px);
            }

            &.large {
                width: 100%;
                margin: 48px 0;
            }
        }

        .top-cards {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .progression-container {
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 200;

        > button {
            position: absolute;
            right: -5px;
            top: 5px;
        }

        .student-progression > .container .name-container {
            display: none;
        }
    }

    @media screen and (max-width: 768px) {
        #student-name-container {
            #card-container {
                flex-direction: column;
            }
        }
    }
}
