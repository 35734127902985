.adaptive-test-header {
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    
    $vertical-padding: 8px;

    padding-block: $vertical-padding;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--black);

    .adaptive-test-header__button {
        align-self: stretch;
        margin-block: calc(-1 * $vertical-padding);
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: var(--color-primary-light);
        text-transform: uppercase;
        font-size: 0.875rem;
        padding: 8px 24px;

        .adaptive-test-header__leave-icon {
            transform: rotate(0.5turn);

            svg {
                fill: var(--black);
            }
        }
    }

    h1 {
        padding-block: 16px;
        font-family: "Roboto";
        font-size: 1.125rem;
    }
}
