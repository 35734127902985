.ds-popover {
    .ds-popover__arrow {
        position: absolute;
        z-index: 1;
        $arrow-size: 18px;

        width: $arrow-size;
        height: $arrow-size;
        transform: translateX(-50%);
        path {
            stroke: var(--white);
            fill: var(--white);
        }

        &[data-placement="top"] {
            top: 100%;
        }
    }
}