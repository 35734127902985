$list-item-border: #a8a8a8;
$unlocked-bg-color: #f2f6fb;
$unlocked-border-color: #006ccb;
$inactive-color: rgba(112, 118, 131, 1);

$dependency-inner-border-color: rgba(255, 255, 255, 0.32);

$lock-dependency-bg-color: #fff7f5;
$lock-dependency-border-color: #ffa68f;
$lock-highlight-dependency-bg-color: #d6370e;
$lock-dependency-toggle-bg: #ff6138;
$lock-dependency-toggle-pin: #ffb6a3;
$lock-dependency-dot-color: #ff6138;

$unlock-highlight-dependency-bg-color: #1e8449;
$unlock-dependency-dot-color: #006ccb;

.manager-list-item {
    border: 1px solid $list-item-border;
    min-height: 68px;
    transition: all 0.5s ease-in-out, border-width 0.2s linear;
    position: relative;

    .manager-list-item__ancestor-tooltip {
        position: absolute;
        top: calc(50% + 8px);
        transform: translateY(-50%);
        left: -50px;
    }

    &.list-item--clickable:hover {
        border-width: 2px;
    }

    .manager-list-item__content {
        align-items: stretch;
        display: flex;
        height: 100%;

        > * {
            padding: 9px 14px;
            display: flex;
            align-items: center;
        }

        > *:first-child {
            flex: 1;
        }

        > *:not(:first-child) {
            border-left: 1px solid $list-item-border;
        }
    }

    &.--unlocked {
        background-color: $unlocked-bg-color;
        border-color: $unlocked-border-color;
    }

    &.--locked {
        color: $inactive-color;
    }

    &.--lockDependency {
        background-color: $lock-dependency-bg-color;
        border-color: $lock-dependency-border-color;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -15px;
            border-radius: 50%;
            height: 8px;
            width: 8px;
            background-color: $lock-dependency-dot-color;
        }

        > *:not(:last-child) {
            border-right: 1px solid $dependency-inner-border-color;
        }

        .toggle {
            background-color: $lock-dependency-toggle-bg;
        }

        .list-item__child--highlighted {
            background-color: $lock-highlight-dependency-bg-color;
            color: white;

            &.--bold {
                font-weight: 700;
            }
        }
    }

    &.--unlockeDependency {
        background-color: $unlocked-bg-color;
        border-color: $unlocked-border-color;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -15px;
            border-radius: 50%;
            height: 8px;
            width: 8px;
            background-color: $unlock-dependency-dot-color;
        }

        > *:not(:last-child) {
            border-right: 1px solid $dependency-inner-border-color;
        }

        .list-item__child--highlighted {
            background-color: $unlock-highlight-dependency-bg-color;
            color: white;

            &.--bold {
                font-weight: 700;
            }
        }
    }
}
