@use "../../scss/_functions.scss";

.ds-card {
    padding: 24px;
    border-radius: 8px;
    background: var(--white);
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    .ds-card__card-title {
        font-size: functions.pxToRem(24);
        font-weight: 400;
        margin-bottom: functions.pxToRem(42);
    }
}

@media screen and (max-width: functions.pxToRem(768)) {
    .ds-card {
        padding: 16px;
    }
}
