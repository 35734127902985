@use '../../../../scss/_mixins.scss';
@import '../../../../scss/Variables.scss';

.HomeModulesList{
    width: calc(100% - 60px);
    height: 100%;
    margin: 0 30px;
        .module{
            width: calc(50% - 24px);
            float: left;
            border-radius: 10px;
            background-color: white;
            margin: 0 12px 24px 12px;
            &:nth-child(even){
                float: right;
            }
            @include mixins.default-box-shadow;
            h3{
                padding: 32px;
                font-size: 1.4em;
                font-family: "Roboto", sans-serif;
                font-weight: 400;
            }
            .objectives{
                display: flex;
                flex-direction: column;
                ul{
                    list-style: circle;
                    padding: 0 32px;
                    li{
                        h4{
                            &:before{
                                content:"\2022";
                                font-size: 32px;
                                padding-right: 4px;
                            }
                            display: flex;
                            align-items: center;
                            padding: 0 0 8px 0;
                            color: $color-primary;
                            font-size: 1em;
                            text-transform: capitalize;
                            span{
                                font-size: 8px;
                                margin: 0 8px 0 0;
                            }
                        }
                        p{
                            padding: 0 16px 16px 16px;
                        }
                    }
                }
                button{
                    width: 100%;
                    padding: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: auto;
                    color: $color-primary;
                    border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
                    span{
                        &:last-child{
                            font-size: 1em;
                            font-weight: 500;
                            text-transform: uppercase;
                            position: relative;
                            &:before{
                                display:block;
                                content: ''; 
                                width: 100%; 
                                height: 1px; 
                                background-color: $color-primary;
                                position: absolute;
                                bottom: -2px;
                                opacity: 0;
                                transition: opacity 0.2s ease-out;                            }
                        }
                    }
                    &:hover{
                        span:last-child{
                            &:before{
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        &.clearfix:after,
        &.clearfix:before{
        content: '';
        display: table;
        clear: both;
        }
    
}

@media screen and (max-width: 900px) {
    .HomeModulesList{
        .module{
            width: 100%;
            margin: 0 0 24px 0;
        }
    }
}