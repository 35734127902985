.objective-list-line .objective-list-line__title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .objective-list-line__title-container__tooltip {
        background-color: rgba(121, 121, 121, 1);
        font-size: 1rem;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 8px;
    }

    .objective-list-line__title {
        text-transform: capitalize;
    }
}