.ds-progress-bar-container{
    width: 100%;
    .ds-progress-bar-container__infos{
        display: flex;
        justify-content: space-between;
        margin: 0 0 8px 0;
    }
    .ds-progress-bar-container__bar{
        border-radius: 8px;
        background-color: var(--lightblue);
       > div{
        border-radius: 8px;
        background-color: var(--primary-blue);
       }
    }
    &.--disabled{
        color: var(--grey-dark);
    }
}