/* Design systen colors */
:root{
    --primary-blue: #006CCB;
    --dark-primary-blue: #0062B9;
    --light-primary-blue: #4694D9;
    --lightblue: #ADCCF5;

    --black: #192A3E;
    --grey: #707683;
    --grey-dark: #535B6D;
    --grey-25: rgba(121, 118, 131, 0.25);
    --dark-grey: #535B6D;
    --medium-grey: #C2CFE0;
    --light-grey: #F3F5F9;
    --white: #fff;
    --white-15: rgba(255, 255, 255, 0.15);
    
    --workmodes-solo: #3B29AB;
    --workmodes-solo-20: #CFCEED;

    --layout-darkblue: #00274A;
    --layout-greyblue: #334D6E;
    --layout-alice-blue: #F2F4FC;
    --layout-lightblue: #F4F7FE;
    --layout-containerincard: #F8FAFC;
    --layout-grey: #FAFAFE;
    --layout-warm-white: #FFFBFE;
    
    --alert-inf-default: #FFA726;
    --alert-inf-hover: #D98A17;
    --alert-inf-layoutbubble: #FAE7CC;
    --alert-inf-chip-dark: #4C4D3F;
    --alert-inf-chip-light: #fff7eb;
    --alert-inf-dark: #D98A17;

    --alert-sup-default: #26A4AB;
    --alert-sup-hover: #3D8A8F;
    --alert-sup-bubble: #CFEBED;
    --alert-sup-chip-dark: #205F74;
    --alert-sup-chip-light: #ebf8f9;
    --alert-sup-dark: #3D8A8F;

    --alert-conf-default: #8AC0EC;
    --alert-conf-hover: #7AADD6;
    --alert-conf-bubble: #E5F1FB;
    --alert-conf-chip-dark: #305D83;
    --alert-conf-chip-light: #E8F2FB;

    --left-plot-filled: #ECF5FC;

    --false: #C81D1D;
    --false-light: #FEF9F9;
    --true: #186A3A; 
    --true-light: #F6FAF8;
    --level: #FFB03B;

    --purple-dark: #5046B9;
    --purple-medium: #C4D3F9;
    --purple-light: #EAF0FD;

    --font-black: #192A3E;
    --font-grey: #707683;


    --objective-completed: #8AC0EC;
    --objective-disabled-completed: #8A9FEC;
    --objective-inProgress: #CBE1EA;
    --objective-disabled-inProgress: #D6DFF7;

    --danger: #E9D1D1;
    --danger-border: #DBB2B2;

    --background-color-primary: #fff;
    --background-color-secondary: #f2f6fb;

    --disabled-primary-light: rgba(243, 245, 249, 1);
    --disabled-primary: rgba(83, 91, 109, 1);

    --mode-solo-ai-primary: rgba(59, 41, 171, 1);
    --mode-teacher-primary: rgba(1, 102, 128, 1);
    --mode-duo-primary: rgba(158, 0, 89, 1);
    --mode-workshop-primary: rgba(0, 68, 176, 1);
    --mode-test-primary: rgba(25, 42, 62, 1);
    --mode-tuto-primary: rgba(168, 31, 0, 1);

    --mode-solo-ai-primary-light: rgba(0, 108, 203, 0.09);
    --mode-duo-primary-light: rgba(158, 0, 89, 0.09);
    --mode-test-primary-light: rgba(243, 245, 249, 1);
    --mode-solo-teacher-primary-light: rgba(25, 133, 161, 0.09);
    --mode-tuto-primary-light: rgba(252, 47, 0, 0.09);
    --mode-workshop-primary-light: rgba(58, 134, 255, 0.09);
    --mode-hover: rgba(244, 247, 254, 1);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
