@use "../../../scss/DesignSystem.scss" as ds;
@use "../../../design-system-components/CardContainer/CardContainer.scss" as card-container;
@use "../../../design-system-components/ModuleCard/ModuleCard.scss" as card;

$padding: 48px;
$max-width: calc(3 * card.$width + 2 * card-container.$gap + 2 * $padding);

.adaptive-tests-dashboard {
    box-sizing: border-box;
    max-width: $max-width;
    padding-top: 50px;
    padding-inline: $padding;
    margin-inline: auto;

    > * {
      margin-bottom: 48px;
    }

    h3 {
        @include ds.h3;
    }

    .introduction {
      font-size: 1em;
      font-weight: 400;
      line-height: 1.1875;
      text-align: left;
      display: flex;

      > p {
        margin: auto 0 auto 16px;
      }
    }

    .adaptive-test-db__card {
      height: 360px;
      &.no-test {
        border: var(--primary-blue) 1px solid
      }
      .no-test-pastille {
        position: absolute;
        left: 385px;
        top: 31px;
        height: 10px;
        width: 10px;
        background-color: var(--primary-blue);
        border-radius: 100px;
      }
      h4 {
        margin:8px 0 42px 0;
      }
      .progress {
        height: 60px;
      }
     }

    .icon-text {
      font-size: 0.75rem;
      display: flex;
      width: 100%;
      background-color: var(--layout-lightblue);

      .icon-text__text {
        margin: auto 4px auto 0;
      }
      .icon-text__icon {
        margin: auto 12px;
      }
    }

  .recommendations {
    height: 45px;
    margin-top: 7px;
  }

    .module-btn {
      border-radius: 10px;
      justify-content: center;
      text-transform: None;
      font-size: 0.875rem;
      line-height: 1.25;
      font-weight: 500;
      letter-spacing: 0.1px;
      padding: 10px 16px 10px 12px;

      &:not(:last-child) {
        margin: 33px 0px 16px 0px;
      }

      .ds-icon {
          margin: 0;
      }

      &.ds-btn--icon-left {
        .ds-btn__label {
          margin-left: 14px;
        }
      }

      &.ds-btn--icon-right {
        .ds-btn__label {
          margin-right: 14px;
        }
      }
    }

    .mobile-module-card {
      display: None;
    }


    @media screen and (max-width: 768px) {

      h3, .adaptive-test-db__card {
        display: None;
      }

      .introduction {
        margin-top: 39px;
        overflow-wrap: break-word;

        .avatar-img {
          width: 40px;
        }
      }

      .mobile-module-card {
        padding: 16px;
        display: flex;
        flex-direction: row;
        width: 347px;
        height: 101px;
        background-color: var(--layout-warm-white);
      }

      .mobile-module-card__title {
        max-width: 248px;
        max-width: 224px;
        font-weight: 500;
        font-size: 1em;
        line-height: 1.5;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: auto;
      }

      .icon-text {
        background-color: var(--layout-warm-white);
      }

      .mobile-module-card__progress-bar {
        width: 224px;
        background-color: var(--layout-warm-white);
      }

      .mobile-module-card__main {
        max-width: 224px;
        margin-left: 12px;
        margin-right: 20.59px;
      }

      .mobile-module-card__index {
        font-size: 1em;
        line-height: 2.5;
        font-weight: 500;
        margin: auto;

      }
      .mobile-module-card__progress-bar {
        top: 0px;
        padding: 0px;
        height: 52px;
      }

      .mobile-module-card .ds-progress-content {
        top: 8px;
        height: 40px;

        .ds-progress-bar__background {
          top: 24px;
        }

        .ds-progress-bar__real {
          top: 24px;
        }

        .ds-progress-bar__estimate {
          top: 19px;
        }
      }
    }
}
