$background-color: #fdfdff;
$info-icon-color: #736abd;
$lick-color: #3885EB;
$font-color: #4E4E4E;

.locked-module-warning-banner {
    padding: 6px 24px;
    background-color: $background-color;
    display: flex;
    align-items: center;
    margin: 16px -24px 32px -24px;

    > p {
        flex: 1;
        color: $font-color;

        .warning-banner__info-icon {
            color: $info-icon-color;
            font-size: 1.3rem;
            margin-right: 4px;

            // For optical alignment
            position: relative;
            top: 4px;
        }

        a {
          color: $lick-color;
          text-decoration: underline;
          margin-left: 4px;
        }
    }
}
