.group-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        max-height: 500px;
        overflow-y: auto;

        > div {
                flex: 1 0 auto;
        }

        .group {
                border-radius: 34px 34px 34px 34px;
                box-shadow: 7px 8px 12px #e8e8e8;
                margin: 10px;
                padding: 25px;
        }

        .group-detail {
                margin: 15px;

                .title {
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 21px;
                        border: 1px solid #2C3E50;
                        box-sizing: border-box;
                        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                        border-radius: 19px;
                        padding: 15px;
                }
                .student {
                        background: #FFFFFF;
                        border: 1px solid;
                        box-sizing: border-box;
                        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                        border-radius: 10px;
                        padding: 15px;
                        margin-top: 5px;

                        &.selected {
                                background-color: #60A4BF;
                        }

                        &.group-1-student {
                                border-color: #DFEDF2;
                        }

                        &.group-2-student {
                                border-color: #E8D8E7;
                        }

                        &.group-3-student {
                                border-color: #CCE8D6;
                        }

                        &.group-4-student {
                                border-color: #EDB18E;
                        }

                        &.group-5-student {
                                border-color: #DCDE90;
                        }
                }
                
        }

        .group-1 {
                background: #DFEDF2;
        }

        .group-2 {
                background: #E8D8E7;
        }

        .group-3 {
                background: #CCE8D6;
        }

        .group-4 {
                background: #EDB18E;
        }

        .group-5 {
                background: #DCDE90;
        }

        .student-name {
                margin-bottom: 5px;
        }
        .group-name {
                margin-bottom: 10px;
        }

}