.student-progression__header-container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .header__main-info {
        display: flex;
        justify-content: space-between;

        .header__student-identity {
            flex: 1;
            display: flex;
            gap: 12px;
            align-items: center;
            flex-wrap: wrap;

            .student-identity__icon {
                width: 100%;
                max-width: 32px;
                height: 32px;
                position: relative;
                isolation: isolate;

                div {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 10;
                }

                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-color: var(--layout-greyblue);
                    width: 32px;
                    height: 32px;
                    border-radius: 100%;
                }
            }

            h1 {
                font-family: Roboto;
                font-size: 1.125rem;
                line-height: 1;
                font-weight: 700;
                width: max-content;
            }

            .header__separator {
                font-size: 1rem;
            }

            p {
                font-size: 1rem;
            }
        }

        .header__close-button {
            align-self: flex-start;
            color: var(--white);
        }
    }

    .header__details {
        display: none;
    }

    @media screen and (max-width: 600px) {
        .header__info-labels {
            display: none;
        }
        .header__details {
            display: block;
        }

        .header__separator {
            display: none;
        }
    }
}
