@use "../../../scss/_functions.scss";
@use "../_vars.scss";

.teacher-auth {
    @include vars.auth-container;

    .teacher-auth__card {
        @include vars.auth-card;

        position: relative;
        a.forgotten-password {
            margin-bottom: functions.pxToRem(42);
        }
    }
    form {
        width: 100%;
        box-sizing: border-box;
        label {
            display: block;
            margin-bottom: functions.pxToRem(12);
        }
        .teacher-auth__input--email {
            margin-bottom: functions.pxToRem(32);
        }
        .teacher-auth__input--password {
            margin-bottom: functions.pxToRem(16);
        }
        .teacher-auth__loginBtn {
            width: 100%;
        }
    }
    .sign-in {
        margin-top: functions.pxToRem(42);
    }
    .previous-step {
        @include vars.auth-change-usertype;
    }
}
