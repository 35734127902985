$has-parent-color: #006ccb;
$light-blue: #f2f6fb;

.batch-controls {
    display: flex;
    align-items: center;
    margin-inline: 50px;

    .batch-controls__content {
        display: flex;
        flex: 1;
        align-items: center;

        .content__title {
            font-weight: 700;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            flex: 1 1 0;
            max-width: max-content;
        }

        .content__separator {
            margin: 0 12px;
            border-right: 1px solid white;
            height: 1.5rem;
        }

        .content__list-title {
            flex: 1;
            margin-right: 32px;
            white-space: nowrap;
        }

        .control__unlock-all {
            display: flex;
            align-items: center;
            white-space: nowrap;

            > span {
                font-size: 1.125rem;
                margin-right: 20px;
            }
        }
    }

    &.batch-controls--has-parent {
        background-color: $has-parent-color;
        border: 1px solid $has-parent-color;
        box-sizing: border-box;
        box-shadow: -6px 6px 20px rgba(0, 0, 0, 0.25);
        clip-path: inset(0px 0px -25px 0px);

        .batch-controls__back {
            height: 100%;
            padding: 24px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $light-blue;
            color: $has-parent-color;

            .batch-controls__back-icon {
                transform: rotate(0.5turn);
            }
        }

        .batch-controls__content {
            color: white;
            justify-content: flex-end;
            padding: 24px;

            .control__unlock-all {
                margin-left: auto;

                .toggle.--selected {
                    background-color: $light-blue;

                    .toggle__pin {
                        background-color: $has-parent-color;
                    }
                }
            }
        }
    }
}
