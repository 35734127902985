@mixin h3 {
  font-weight: 400;
  font-size: 2.125rem;
  line-height: 2.5;
}

@mixin h4 {
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0em;
}

@mixin h5 {
    font-size: 1.5rem;
    letter-spacing: 0;
    text-transform: capitalize;
    font-family: "Roboto", "sans-serif";
    font-weight: 500;
    color: var(--font-black);
}

