.GoToTopButton{
    button{
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        right: 24px;
        bottom: 20%;
        border-radius: 100%;
        background-color: white;
        box-shadow: 0 0 8px rgba($color: #000000, $alpha: 0.2);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-out;
    }
    &.show{
        button{
            visibility: visible;
            opacity: 1;
        }
    }
}