@use "../../../../../scss/functions";

.student-manager {
    height: 100%;
    .student-manager__actions {
        display: flex;
        margin-top: 60px;
        margin-bottom: 20px;
        .student-manager__actions__add-students{
            margin-left: auto;
        }
    }

    .student-manager__table {
        width: 100%;
        border-collapse: collapse;

        tbody {
            width: 100%;

            tr {
                padding: 24px 0;
                border-bottom: 1px solid var(--grey-25);
                &.student-manager__header{
                    padding-top: 0;
                }
                th,
                td {
                    text-align: left;
                    padding: 24px 0;
                }

                th {
                    color: var(--grey);
                    font-weight: 400;
                }

                td {
                    .student-manager__edit-name-idle {
                        .student-manager__student-details--btn{
                            padding: 16px 16px 16px 0;
                            font-size: 1rem;
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                    .student-manager__edit-name-edit {
                        form {
                            width: 100%;
                            display: flex;
                            gap: 1rem;

                            .student-manager__edit-name-edit__validate,
                            .student-manager__edit-name-edit__cancel {
                                color: var(--color-primary);
                                font-weight: 500;
                                font-size: functions.pxToRem(18);
                                padding: 0 16px;
                                &:hover {
                                    text-decoration: underline;
                                    font-weight: 600;
                                }
                            }

                            .student-manager__edit-name-edit__validate {
                                margin-right: 1rem;
                            }
                        }
                    }

                    input[type=checkbox] {
                        width: functions.pxToRem(20);
                        height: functions.pxToRem(20);
                    }


                    &.student-manager__options {
                        display: flex;
                        justify-content: flex-end;
                    }
                }

                
            }

            .student-manager__table__header {
                th {
                    &:first-child {
                        .student-manager__table__placeholder {
                            width: 13px;
                            height: 13px;
                        }
                    }
                }
            }
        }
    }

    .student-manager__no-students{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        > div{
            display: flex;
            flex-direction: column;
            justify-content: center;
            > div{
                font-size: functions.pxToRem(20);
                text-align: center;
                margin: 0 0 40px 0;
            }
        }
    }
}

@media (max-width: 50rem) {
    .student-manager {
        .student-manager__actions{
            flex-wrap: wrap;
            margin-top: 24px;
            > *{
                width: 100%;
            }
            > div:first-child{
                margin-bottom: 16px;
            }
        }
        .student-manager__table {
            tbody {
                tr {
                    .student-manager__table__classroom-code{
                        display: none;
                    }
                    th, td{
                        &.student-manager__table__center{
                            text-align: center;
                        }
                    }
                    td {
                        .student-manager__edit-name-edit {
                            form {
                                flex-direction: column;
                                gap: 1rem;
                                .student-manager__edit-name-edit__validate,
                                .student-manager__edit-name-edit__cancel {
                                    margin: 24px 0 0 0;
                                }
                            }
                        }
                        input[type=checkbox] {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}