@import "../../scss/Variables.scss";

.header-top {
    height: 66px;
    padding: 0;
    background-color: #fff;
    position: relative;
    z-index: 1;

    .container {
        width: 100%;
        height: 66px;
        display: flex;
        align-items: center;
        margin: 0 auto;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #fff;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);

        .logo {
            margin: 0 0 0 24px;
        }

        .header__backlink {
            margin-left: 24px;
            border-radius: 10px;
            border-width: 1px;

            svg {
                transform: rotate(0.5turn);
            }
        }
    }
}

@media screen and (min-width: 981px) {
    .header-top .container {
        height: 66px;

        .menu-container {
            margin: 0 0 0 auto;
            display: flex;
            align-items: center;
            height: 100%;

            nav {
                display: flex;
                align-items: center;
                height: 100%;

                .header__mobile-menu-btn {
                    display: none;
                }

                ul {
                    display: flex;
                    align-items: center;
                    height: 100%;

                    li {
                        display: inline-block;
                        margin: 0 24px;
                        position: relative;

                        a {
                            color: $color-primary;
                            font-size: 1em;

                            &:after {
                                content: "";
                                display: block;
                                width: 100%;
                                height: 2px;
                                position: absolute;
                                left: 0;
                                bottom: -10px;
                                transition: all 0.2s ease-out;
                            }
                            &:hover,
                            &.active {
                                transition: bottom 0.2s,
                                    background-color 0.2s ease-out;
                                font-weight: 700;

                                &:after {
                                    bottom: -4px;
                                    background-color: $color-primary;
                                }
                            }

                            // To prevent resize when hovering: https://stackoverflow.com/questions/556153/inline-elements-shifting-when-made-bold-on-hover
                            &:before {
                                display: block;
                                content: attr(title);
                                font-weight: bold;
                                height: 0;
                                overflow: hidden;
                                visibility: hidden;
                            }
                        }

                        &:last-child {
                            padding: 0;
                        }
                    }
                }

                .header__logout-btn {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    border-left: 1px solid $color-neutral-gray;
                    font-size: 1em;
                    font-family: "Roboto", sans-serif;
                    padding: 24px 24px;

                    span {
                        color: $color-primary;

                        &.label {
                            display: flex;
                            align-items: center;
                        }
                        &.label_icon {
                            fill: $color-primary;
                            margin: 0 4px 0 0;
                            font-size: 20px;
                        }
                    }

                    &:hover {
                        color: $color-primary;

                        span.label {
                            font-weight: 700;
                        }
                    }
                }

                .header__button--action-button {
                    padding: 6px 24px;
                    margin: 0 12px;
                }
            }
        }
    }
}

/*Menu tablet portrait and smartphone*/
@media screen and (max-width: 980px) {
    .header-top {
        .container {
            .menu-container {
                display: flex;
                width: 300px;
                height: 100%;
                align-items: center;
                justify-content: center;
                position: fixed;
                right: 0;
                top: 0;
                background-color: #fff;
                box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
                transform: translateX(300px);
                transition: all 0.2s ease-out;

                nav {
                    .header__mobile-menu-btn {
                        span {
                            font-size: 32px;
                        }

                        &.header__mobile-menu-btn--open {
                            position: absolute;
                            top: 10px;
                            left: -80px;
                        }
                        &.header__mobile-menu-btn--close {
                            position: absolute;
                            top: 10px;
                            right: 24px;
                        }
                    }

                    ul {
                        display: none;

                        li {
                            text-align: center;
                            padding: 24px 0;

                            a {
                                font-size: 1.1em;
                                font-weight: 700;

                                &:after {
                                    content: "";
                                    width: 50px;
                                    height: 2px;
                                    display: block;
                                    margin: 8px auto 0 auto;
                                    background-color: $color-primary;
                                }
                            }
                        }
                    }

                    .header__logout-btn {
                        display: none;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        padding: 24px 0;
                        font-family: "Roboto", sans-serif;
                        font-size: 1.1em;

                        span {
                            &.label {
                                display: flex;
                                align-items: center;
                                .label_icon {
                                    margin: 0 4px 0 0;
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }

                &.menu-container--show {
                    transform: none;

                    nav {
                        ul {
                            display: unset;
                        }
                        .header__logout-btn {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}

.--custom-color-headerTxt .header-top .menu-container {
    nav li a {
        color: var(--custom-color-header-txt);

        &.active:after,
        &:hover:after {
            background-color: var(--custom-color-header-txt);
        }

        @media screen and (max-width: 980px) {
            &:after {
                background-color: var(--custom-color-header-txt);
            }
        }
    }

    button .label {
        color: var(--custom-color-header-txt);

        .icon {
            color: var(--custom-color-header-txt);
        }
    }
}
