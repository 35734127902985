@import "../../../../scss/Variables.scss";

$handlerHeight: 40px;
$fakeHandlerWidth: $handlerHeight;

.teacher-info__drawer__container {
    // override Drawer default styles
    position: absolute !important;
    z-index: 9 !important;
    .rc-drawer-content-wrapper-hidden {
        display: initial !important;
    }
    .rc-drawer-content-wrapper, .rc-drawer-content {
        overflow: initial !important;
    }
    // motion style
    .rc-drawer-content-wrapper-hidden, .teacher-info__drawer--motion-leave {
        transform: translateX(calc(-100% + $fakeHandlerWidth));
    }
}


.teacher-player-info-panel {
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 0 8px rgba(0,0,0,.15);


    .teacher-info__drawer__content {
        flex: 1;
        margin-right: $fakeHandlerWidth;
    }
    

    .title {
        background-color: rgb(233, 249, 252);
        font: normal normal bold 16px/21px Roboto;
    }

    .sub-title {
        margin-bottom: 8px;
        font: normal normal bold 16px/21px Roboto;
        color: $text-color-primary !important;
    }
    .description {
        font: normal normal normal 14px/19px Roboto;
        color: $text-color-primary !important;
    }

    .panel-section {
        background-color: $background-color-gray;
        border: none !important;
        margin-bottom: 10px;
        margin-left: 24px;
        border-radius: 6px;
        padding: 20px;

        .rc-collapse-content-box {
            margin-top: 0 22px;
        }
    }


    .rc-collapse {
        border: none !important;
        background-color: $background-color-primary;

        .rc-collapse-item {
            border: none !important;
            background-color: $background-color-primary;
            margin-top: 20px;

            .rc-collapse-header {
                background-color: $background-color-primary;
                text-transform: capitalize;
                .custom-arrow {
                    margin-right: 8px;
                }

                .rc-collapse-header-text {
                    color: $text-color-primary !important;
                }
            }
        }
    }

    .teacher-info-drawer__hidden-handler {
        position: absolute;
        right: 0; 
        height: 100%;
        width: $fakeHandlerWidth;
    }

    .drawer-handle {
        height: $handlerHeight;
        padding: 3px 10px;

        background: linear-gradient(
            180deg,
            $background-color-gray 47px,
            $background-color-primary 00%
        );

        display: flex;
        flex-direction: row-reverse;

        .custom-drawer-handle-icon {
            width: 24px;
        }
    }
}
