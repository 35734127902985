@import "../../scss/Variables.scss";

.btn {
    width: 100%;
    min-width: 150px;
    border-radius: 10px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    border: 2px solid;
    transition: all 0.2s ease-out;
    box-shadow: 0 4px 10px rgba($color: #000000, $alpha: 0.25);
    position: relative;
    .evb-spinner__container{
        right: -95px;
        top: 50%;
        left: auto;
        transform: translate(0,-50%) scale(50%);
    }
    &:not(.centered){
        .label{ }
    }
    span {
        &.label {
            order:2;
            text-align: left;
        }
    }
    .icon-container{
        .icon-wrapper{
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            width: 20px;
            height: 20px;
            border: 2px solid;
            span{
                font-size: 12px;
            }
        }
        &.left{
            order:1;
            margin: 0 auto 0 0;
            padding-right: 8px;
        }
        &.right{
            order:3;
            margin: 0 0 0 auto;
            padding-left: 8px;
        }
        &.no-circle{
            .icon-wrapper{
                border: none;
                &.left{
                    margin: 0 8px 0 0;
                }
                &.right{
                    margin: 0 0 0 8px;
                }
                span{
                    font-size: 16px;
                }
            }
        }
    }
    &.primary {
        background-color: $color-secondary;
        border-color: $color-secondary;
        span {
            color: #fff;
        }
        &:hover {
            background-color: #fff;
            span {
                color: $color-secondary;
            }
        }
    }

    &.secondary { }

    &.tertiary {
        background-color: $color-primary;
        border-color: transparent;
        span {
            color: #fff;
        }
        &:hover {
            background-color: #fff;
            border-color: $color-primary;
            span {
                color: $color-primary;
            }
        }
    }

    &.centered {
        span {
            &.label {
                width: 100%;
                text-align: center;
            }
        }
    }

    &.disabled{
        cursor: not-allowed;
        background-color: $color-undefined;
        border-color: $color-undefined;
        label{color: white;}
        &:hover{
            border-color: $color-undefined;
            span{color: white;}
            background-color: $color-undefined;
        }
    }
}
