.user-authentication-container{
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary);

    .auth___mascot{
        position: absolute;
        left: -125px;
        top: 50%;
        transform: translateY(-50%);
    }
    .auth-error{
        border-radius: 0 0 10px 10px;
    }

    .user-auth__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;

        .user-auth__app-select {
            align-self: flex-end;
        }
    }
}

@media (max-width: 50rem) {
    .user-authentication-container{
        .auth___mascot{
            display: none;
        }
    }
}