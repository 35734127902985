@use "../../../design-system-components/LayerModal/var.scss" as layer-modal;

.student-progression {
    min-width: 900px;

    // Style inner div of modal
    > div {
        min-height: 750px;
        display: flex;
        flex-direction: column;

        .student-progression__modal-main-layer {
            flex: 1;
            display: flex;
            flex-direction: column;

            .student-test-progression__results {
                flex: 1;
                height: 0;
                margin: - layer-modal.$main-layer-padding;
            }
        }
    }
}

@media screen and (max-width: 1240px) {
    .student-progression {
        min-width: 90vw;
    }
}

@media screen and (max-width: 600px), screen and (max-height: 850px) {
    .student-progression > div {
        min-height: initial;
    }
}