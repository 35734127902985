@use "../../../../../scss/functions" as fn;

$spacing: 32px;

.wizard-interrupting-video {
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }

    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .wizard-interrupting-video__instruction {
        width: 100%;
    }

    .wizard-interrupting-video__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: $spacing;
        width: 100%;
        max-width: 1126px;
        margin-inline: auto;
        padding: $spacing;

        .wizard-interrupting-video__video {
            width: 100%;
            display: block;
        }

        .wizard-interrupting-video__btn-list {
            display: flex;

            & > *:nth-child(2n) {
                margin-left: auto;
            }

            .btn-list__transcript-btn svg {
                margin-block: -10px;
            }
        }
    }
}

@media screen and (max-width: fn.pxToRem(768)) {
    .wizard-interrupting-video
        .wizard-interrupting-video__content
        .wizard-interrupting-video__btn-list {
        flex-direction: column;
        gap: calc($spacing * 0.5);

        > button {
            width: 100%;
        }
    }
}
