@import "../../scss/Variables.scss";

.error-notification {
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }

    border-radius: 10px;
    font-size: 0.875rem;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: stretch;
    
    > * {
        padding: 20px;
        max-width: 500px;
        display: flex;
        align-items: center;
    }
    
    > button {
        color: #0079f2;
        font-weight: 700;
    }
    
    > *:not(:last-child) {
        border-right: 1px solid rgba(121, 121, 121, 0.38);
    }
    
    .error-notification__dismiss-button {
        color: #8f8f8f;
    }
    
    &.--success {
        background-color: #f5fdf7;
    }
    &.--failure {
        background-color: #fff1f2;
    }
    &.--neutral {
        background-color: white;
    }
}
