@use "../../scss/_functions.scss" as fn;

@mixin auth-container {
    max-width: fn.pxToRem(560);
    width: 100%;

    > *:not(:last-child) {
        margin-bottom: 34px;
    }

    > a:first-child {
        width: fit-content;
        font-size: fn.pxToRem(18);
    }
}

@mixin auth-card {
    padding: 52px;
    @media screen and (max-width: fn.pxToRem(768)) {
        padding: 16px;
    }

    .ds-card__card-title {
        font-family: 'Roboto', sans-serif;
        font-size: fn.pxToRem(24);
    }

    a:not(.ds-btn) {
        display: inline-block;
        color: var(--primary-blue);
        text-decoration: underline;
    }
}

@mixin auth-change-usertype {
    color: var(--white);

    a {
        text-decoration: underline;
    }
}
