@import "../../scss/Variables.scss";

$left-right-margin: 32px;

#module-list {
    margin-top: 48px;
    height: calc(100% - 138px);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .avatarBottom {
        position: absolute;
        bottom: 0;
        right: 24px;
    }
    #welcome-message {
        align-self: flex-start;
        width: 50vw;
        background: #fff;
        display: flex;
        align-items: center;
        border-radius: 0 56px 56px 0;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
        padding: 8px 8px 8px 24px;
        img.avatar-img {
            max-width: 80px;
            max-height: 80px;
            height: auto;
        }
        div.text-content {
            margin-left: 16px;
        }
    }

    #specimen-content {
        align-self: flex-start;
        margin: 32px calc($left-right-margin * 2) 0 calc($left-right-margin * 2);

        button {
            margin-top: 16px;
        }
    }

    #card-container {
        width: calc(100% - 64px);
        display: flex;
        margin: 32px $left-right-margin;
        flex-wrap: wrap;

        > .new-student-module-card {
            margin: 25px;
        }
    }
    @media screen and (max-width: 768px) {
        #card-container {
            justify-content: center;
        }
    }
}