@use "../../../../../../scss/functions" as fn;

.transcript-modal {
    $spacing: 24px;

    box-sizing: border-box;
    * {
      box-sizing: border-box;
    }

    box-shadow: 2px 24px 38px rgba(0, 0, 0, 0.14);
    border-radius: 5px;
    margin: 0;
    $mascot-width: 60px;
    $p-max-width: 60ch;
    width: calc($mascot-width + $p-max-width + (3 * $spacing));
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: $spacing;

    header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2 * $spacing;

        h2 {
            font-size: fn.pxToRem(24);
            font-weight: 400;
        }

        button {
          color: var(--black);
        }
    }

    .transcript-modal__content {
      margin-inline: auto;
      margin-bottom: $spacing;
      display: flex;
      align-items: flex-start;
      gap: $spacing;

      img {
        width: $mascot-width;
      }
    }

    footer {
        margin: -1 * $spacing;
        margin-top: 0;
        padding: 16px;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.04),
            0px 6px 18px rgba(0, 0, 0, 0.04);

        .transcript-modal__btn {
            margin-inline: auto;
            width: fit-content;
        }
    }
}

@media screen and (max-width: fn.pxToRem(768)) {
    .transcript-modal {
        $margin: 8px;
        width: calc(100% - (2 * $margin));
        margin-inline: $margin;
        left: 0;
        transform: translateY(-50%);
    }
}
