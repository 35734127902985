.test-info-modal .rc-dialog {
    position: absolute;
    top: 144px;
    left: 0;
    margin: 0;
    width: 750px;

    .rc-dialog-content {
        border-radius: 0 50px 50px 0;
        background-color: #f2f6fb; // TODO: get color from theme/variables
        box-shadow: 4px 4px 30px #007fa3;
        
        .rc-dialog-body {
            display: flex;
            gap: 50px;
            padding-left: 88px;
            padding-right: 64px;

            .test-info-modal__img-container {
                flex: 1 0;
                flex-basis: 40px;
                min-height: 100px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }

            > p {
                flex: 4 0 min-content;
                font-size: 1.125rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                > button {
                    width: auto;
                    margin: 8px auto 0 auto;
                }
            }
        }
    }
}

@media screen and (max-width: 1239px) {
    .test-info-modal .rc-dialog {
        width: auto;
        right: 10px;

        .rc-dialog-content .rc-dialog-body {
            > img {
                margin-left: 16px;
            }

            > p {
                margin-right: 16px;
            }
        }
    }
}

@media screen and (max-width: 50rem) {
    .test-info-modal .rc-dialog .rc-dialog-content .rc-dialog-body {
        flex-direction: column;
        gap: 32px;
    }
}
