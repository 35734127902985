@use "../../../../scss/functions";
@import "../../../../scss/Variables.scss";

.classroom-list-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    .classroom-list__header{
        display: flex;
        align-items: center;
        margin: 16px 0 24px 0;
        h1{
            margin: 0;
        }
        button{
            margin-left: auto;
        }
    }
    .tabs-container{
        display: flex;
        margin: 24px 0 0 0;
        flex-wrap: wrap;
        .ds-tabs {
            width: 300px;
            margin: 0 16px 16px 0;
            [role="tabpanel"]{
                height: 395px;
                > span{
                    display: block;
                    margin: 0 0 8px 0;
                }
            }
        }
        .classroomInfos{
            margin: 0 0 16px 0;
            flex-wrap: wrap;
            .code{
                width: 100%;
                margin: 0 8px 8px 0;
                text-align: center;
                span{
                    &:nth-child(2){
                        font-weight: 700;
                    }
                }
            }
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;
                color: $color-dashboard-primary;
                padding: 16px 0;
                background-color: $background-color-secondary;
                color: $color-dashboard-primary;
                border-radius: 10px;
                span{
                    &:first-child{
                       margin-right: 4px;
                    }
                    &:nth-child(2){
                        text-decoration: underline;
                        padding: 0 0 0 4px;
                        font-size: 1em;
                    }
                }       
            }
        }
        .no-data{
            padding: 16px;
            background-color: $background-color-secondary;
            border-radius: 10px;
        }
    }
    .no-classrooms{
        height: 100%;
    }
    .no-classrooms, .no-classrooms > div{
        display: flex;
        align-items: center;
        justify-content: center;
        > div{
            flex-direction: column;
            > div{
                font-size: functions.pxToRem(20);
                text-align: center;
                margin: 40px 0;
            }
        }
    }
}

div.classroom-list__classroom-creator{
    width: functions.pxToRem(600);
}

div.classroom-created-modal{
    max-width: 600px;

    .ds-dialog {
        padding: 24px;
    }
    .classroom-created-modal-content{
        .classroom-created-modal-content__code{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 24px;
            span{
                display: block;
                margin: 8px 0 0 0;
                font-size: functions.pxToRem(32);
            }
        }
        p{
            margin: 24px 0;
        }
        .classroom-created-modal-content__actions{
            display: flex;
            justify-content: flex-end;
            border-top: 1px solid var(--light-grey);
            padding: 16px 24px;
        }
    }
}

@media (max-width: 50rem) {
    div.classroom-list__classroom-creator,
    div.classroom-created-modal{
        width: 100%;
        max-width: 100%;
    }
}