@use "./vars";
@use "../../scss/_functions.scss";

.ds-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    * {
        height: inherit;
        width: inherit;
        display: flex;
        align-items: center;
    }

    &.ds-icon--small {
        width: functions.pxToRem(16);
        height: functions.pxToRem(16);
    }
    &.ds-icon--medium {
        width: vars.$medium-icon-size;
        height: vars.$medium-icon-size;
    }
    &.ds-icon--large {
        width: functions.pxToRem(32);
        height: functions.pxToRem(32);
    }
    &.ds-icon--clickable {
        width: functions.pxToRem(48);
        height: functions.pxToRem(48);
    }
    img{
        display: block;
    }
}
