.group-body {
    display: flex;
    flex-direction: column;
    .classroom-navigation{
        display: flex;
        align-items: center;
        margin: 16px 0 24px 0;
        h1{
            margin: 0;
        }
        .selects{
            display: flex;
            margin: 0 24px;
            .custom-select{
                margin: 0 16px; 
            }
        }
        .btn{
            width: fit-content;
            margin-left: auto;
        }
    }

    .clusters-container {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;

        .clusters__left {
            flex: 2 0 0;
            min-width: 800px;

            .groupe-circles {
                background: #d7e6f5;
                border-radius: 15px;
                border-radius: 15px;
            }
        }

        .clusters__right {
            max-width: 500px;
            flex: 1 0 0;

            h2 {
                font-family: "Roboto";
                text-transform: capitalize;
                font-size: 18px;
                font-weight: 700;
                margin-left: 20px;
            }

            .characteristics-container {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

@media (max-width: 1300px) {
    .group-body .clusters-container .clusters__right {
        max-width: 100%;
        flex-grow: 1;

        .characteristics-container {
            flex-direction: row;
        }
    }
}
