@import "../../../scss/Variables.scss";

.work-modes {
    display: grid;
    gap: 16px 8px;

    .work-modes__button {
        width: 100%;

        &:focus {
            outline: 1px solid $color-primary;
        }

        &.--disabled {
            pointer-events: none;
        }

        &.--not-clickable {
            pointer-events: none;
        }

        .work-mode-card {
            display: block;
            height: 100%;
        }
    }

    @media screen and (max-width: 768px) {
        .work-modes {
            gap: 14px 8px;
        }
    }
}
