@use "../../vars";

.password-change-error {
    @include vars.auth-card;

    .password-change-error__content > *:not(:last-child) {
        margin-bottom: 32px;
    }

    .pwd-change-error-icon {
        margin-inline: auto;
    }

    .pwd-change-error-redirection {
        margin-top: 84px;
        width: 100%;
    }
}
