@use "../../../../scss/functions" as fn;

$padding-inline: 30px;

.breadcrumbs-header {
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }

    padding: 13px $padding-inline;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .player-header__breadcrumbs {
        display: flex;
        gap: 20px;

        li {
            background-color: white;
            border-radius: 10px;
            padding: 8px 16px;
        }
    }

    .player-header__leave-link {
        color: white;
    }
}

@media screen and (max-width: fn.pxToRem(768)) {
    .breadcrumbs-header {
        gap: $padding-inline;

        .player-header__breadcrumbs {
            flex: 1;
            flex-direction: column;
            align-items: stretch;
            gap: 2px;
        }
    }
}
