@use "../../../../../scss/mixins";
@use "../../../../../scss/Variables" as var;
@use "../../../../../scss/functions" as fn;

.wizard-instruction {
    background-color: white;
    border-bottom: 1px solid #878c99;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
        0px 1px 3px rgba(0, 0, 0, 0.3);
    padding: 24px 64px;
    display: flex;
    align-items: center;
    position: relative;

    .wizard-instruction__feedback-placeholder {
        width: 0;

        &.--animate-feedback {
            transition: width 0.2s ease-out;
            width: 30%;
        }
    }

    .wizard-instruction__content {
        @include mixins.adaptive-width(1032);
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
        gap: 1em;

        > p,
        > label {
            align-self: center;
            font-size: 1.2em;
            text-align: left;
        }

        &.wizard-instruction__content--audio-only {
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
    }

    .wizard-instruction__content-block {
        width: 100%;
    }
}

@media screen and (max-width: fn.pxToRem(768)) {
    .wizard-instruction {
        border-bottom: none;
        box-shadow: none;
        padding-right: 32px;

        .wizard-instruction__content .tts-btn {
            position: absolute;
            bottom: 0;
            right: 36px;
            transform: translateY(50%);
        }
    }
}
