@use "../../../../../../scss/functions";

.search-student{
    display: flex;
    align-items: center;
    .search-student__input{
        width: 250px;
        padding: 12px 12px 12px 16px;
        background: none;
        border: none;
    }
}