.ds-toggle-button {



    .ds-icon {
      border-radius: 50%;
      display: flex;
      width: 35px;
      height: 35px;
      border-color: transparent;
      background-color: var(--layout-lightblue);
      color: var(--black);
      justify-content: center;
      pointer-events: none;
        svg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
      }

      &:not(:disabled):hover {
        background-color: var(--primary-blue);
        border-color: var(--primary-blue);
        color: white;

        .ds-icon svg {
          fill: var(--white)
        }
      }
    }

    &.ds-toggle-button-bottom {
        .ds-icon {
            transform: rotate(90deg);
        }
    }
    &.ds-toggle-button-left {
        .ds-icon {
            transform: rotate(180deg);
        }
    }
    &.ds-toggle-button-up {
        .ds-icon {
            transform: rotate(-90deg);
        }
    }
    &.ds-toggle-button-right {
        .ds-icon {
            transform: rotate(0deg);
        }
    }
 }
