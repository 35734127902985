@use "./vars";

.ds-table {
  border-collapse: collapse;

  box-sizing:border-box;
  * {
    box-sizing: border-box;
  }

  tr:not(:last-child) {
    border-bottom: vars.$ds-t--table-border;
    height: 100%;
  }

  td, th {
    padding: 12px 0;
    width: fit-content;
    height: 100%;

    &:not(:first-child) {
      padding-left: 8px;
    }
  }

  th {
    color: var(--font-grey);
    font-size: 0.75rem;
    text-align: left;
    font-weight: 500;
    padding-left: 5px;
    padding-top: 0;
  }
}