@use "../../../../scss/_mixins.scss";
@use "./WizardFeedback/WizardFeedback.scss" as fb;

$feedback-margin: 32px;

.wizard-shell-container {
    display: flex;
    align-items: stretch;
    overflow: hidden;

    .wizard__exercise-zone {
        flex: 1;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .notifications-container {
            background: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 16px;
            padding-bottom: 25px;
    
            &:empty {
                display: none;
            }
        }

        .gp-wrapper {
            flex: 1;
            position: relative;
            isolation: isolate;
            overflow: hidden;
            background-color: var(--background-color-secondary);

            .gp-wrapper__content {
                padding: 0 16px 24px 0;
            }

            .mascot-zone {
                position: absolute;
                bottom: 0;
                right: 5vw;
            }
        }

        .emotional-report__container--wizard {
            position: absolute;
            right: 32px;
            bottom: 32px;
            &.emotional-report__container--feedback {
                right: calc(30% + 32px);
            }
        }
    }

    .exercise-identifier {
        flex: 0 0 auto;
    }
}

@media screen and (min-width: 1240px) {
    .wizard-shell-container{
        .wizard__play-zone {
            .gp-wrapper{
                &.solved{
                    width: calc(100% - (fb.$feedback-width--lg + $feedback-margin));
                }
            }
        }
    }
}

@media screen and (max-width: 1239px) {
    .wizard-shell-container {
        .wizard__play-zone {
            .gp-wrapper{
                &.solved{
                    width: calc(100% - (fb.$feedback-width--md + $feedback-margin));
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .wizard-shell-container {
        flex-direction: column;
        
        .wizard__exercise-zone {
            .gp-wrapper{
                &.solved{
                    width: calc(fb.$feedback-width--sm - $feedback-margin);
                }
            }
        }
    }
}