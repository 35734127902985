@use "../../../scss/DesignSystem.scss" as ds;
@use "../AdaptiveTestModuleList/AdaptiveTestModuleList.scss" as adaptive-test;

$max-width: 841px;

.adaptive-tests-results {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  max-width: calc($max-width + 2 * adaptive-test.$padding);
  padding: adaptive-test.$padding;
  margin-inline: auto;
  margin-bottom: 50px;
  position: relative;

  .title_card {
    h3 {
      @include ds.h3;
      margin: 52px 0px 48px;
    }

    h4 {
      @include ds.h4;
      display: flex;
    }

    .subtitle {
      text-align: left;

      .go-back-arrow {
        cursor: pointer;
        margin: auto 0;
      }
    }
  }

  .result-boxes {
    position: relative;
    margin: 33px 0px 25px 0;
    width: 100%;
    box-sizing: border-box;

    .ds-button {
      text-transform: None;
      align-items: center;
      justify-content: center;
    }

    .main-test-card {
      width: 100%;
      min-height: 263px;
      padding: 0;

      &.main-test-card__tall {
        /* To constain warning banner if test is ongoing */
        min-height: 312px;

        .main-test-card__banner {
          padding: 0;
          border-radius: 0 0 100px;
          border-radius: 5px 5px 0 0px;
          min-height: 48px;
          background-color: rgba(230, 99, 66, 0.07);
          width: 100%;
          display: flex;
          line-height: 20px;
          font-size: 0.875rem;
          box-sizing: content-box;

          .warning-icon {
            padding: 0 26px;
          }

          span {
            margin: auto 0;
          }
        }
      }

      .main-test-card__title {
        margin-bottom: 28px;
        font-size: 1rem;

        .main-test-card__title_fixed {
          margin-bottom: 4px;
          font-weight: 500;
          line-height: 1.5;
        }
      }

      .main-test-card__main {
        padding: 24px;
      }

      .ds-progress-bar {
        height: 61px;
        &.ongoing {
          padding: 16px 11px;
          .ds-progress-bar__background,  .ds-progress-bar__real, .ds-progress-bar__estimate  {
            top: 25px;
          }
        }
      }

      .module-btn {
        border-radius: 10px;
        padding: 12px 16px;
        line-height: 1.1875;
        margin-top: 32px;

        .ds-icon {
          line-height: 0.5;
          margin-right: 14px;
        }
      }
    }

    .secondary-card-container {
      position: relative;
      display: flex;
      margin-top: 24px;
      width: 100%;

      .extensible_card {
        height: fit-content;
      }
    }

    .history {
      margin: 0 24px 0 0;
      width: 481px;
      height: 291px;
      padding: 16px;

      .history__results {
        height: 20px;
        display: flex;
        border-top: 1px solid black;
      }

      .history__details {
        width: 100%;
      }

      .option {
        color: var(--primary-blue);
      }

      .ds-progress-bar {
        margin-top: 40px;
        height: 84px;

        .ds-bar-label {
          font-size: 0.875rem;
        }
      }

      .history__select {
        display: flex;

        .toggle-card {
          margin-top:3.5px;

          div {
            margin-right: 4px;
          }
        }
      }

      .results-toggle {
        width: 100%;
        margin: 0;
        padding: 0;
      }
    }

    .recommendations {
      width: 336px;
      margin-bottom: 100px;

      .ds-toggle-div {
        .ds-btn__label::first-letter {
          text-transform: uppercase;
        }
      }

      .recommendations__details {
        margin-top: 42.5px;
        font-size: 0.875rem;

        a {
          color: var(--primary-blue);
          text-decoration: underline;
        }

        li:not(:last-child) {
          margin-bottom: 24px;
          overflow-wrap: break-word;
        }

        li:last-child {
          margin-bottom: 8px;
          overflow-wrap: break-word;
        }

        .recommendation__link-detail {
          margin-left: 8px;
        }
      }
    }

    .ds-progress-content {
      height: 100%;
    }

    .toggle-card {
      display: flex;
      line-height: 1.1875;
      margin: 0;

      .ds-toggle-button {
        margin-left: 16px;
      }

      > div {
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    .separator {
      margin-bottom: 33px;
      margin-top: 28px;
      border-bottom: 1px solid black;
    }
  }

  .floating-btn {
    position: sticky;
    border-radius: 16px;
    height: 56px;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.0015em;
    line-height: 1.4375;
    bottom: 37px;
    left: 50%;
    transform: translateX(-50%);
    width: 226px;
    z-index: 1;

    .ds-btn__label {
      margin-left: 14px;
    }
  }

  @media screen and (min-width: 769px) {
    .mobile {
      display: None;
    }
  }

  @media screen and (max-width: 768px) {
    h3, h4, .desktop {
      display: None;
    }

    .ds-card-container {
      width: 100%;
    }

    .result-boxes {
      margin: auto;
      width: 100%;
      flex-direction: column;
      padding: 16px;
      box-sizing: border-box;
      overflow:hidden;

      .main-test-card {
        &.main-test-card__tall {
          .main-test-card__banner {
            .warning-icon {
              padding: 0 10px;
            }
          }
        }
      }

      .ds-module-card {
        margin-bottom: 16px;
        width: 100%;
      }

      .main-test__result {
          margin: 16px;
      }

      .secondary-card-container {
        margin-top: 0;

        .history, .recommendations {
          min-width: 100%;
        }

        .recommendations {
          margin: 16px;
        }
      }
    }

    .floating-btn {
      left: 50%;
      transform: translateX(-50%);
      bottom: 21px;
      width: 234px;
    }
  }
}