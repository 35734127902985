@import "../../../scss/Variables.scss";
$validation-border: rgba(121, 121, 121, 0.18);
$cancel-color: #8f8f8f;
$confirm-color: #4e4e4e;
$confirm-bg: #e1f1ff;
$confirm-bg-inactive: #f0f0f0;

.prm__action-tray {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 0 32px;
    border-top: 3px solid $validation-border;
    background-color: white;

    > button {
        text-transform: uppercase;
        padding: 16px 24px;
        margin: 24px 0;
    }

    .action-tray__cancel {
        color: $cancel-color;
    }

    .action-tray__confirm {
        color: $confirm-color;
        background-color: $confirm-bg;
        border-radius: 100px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

        &.--inactive {
            pointer-events: none;
            background-color: $confirm-bg-inactive;
            box-shadow: none;
        }
    }
}

.prm__success-title {
    display: flex;
    align-items: center;

    .prm__success-icon {
        color: $color-correct;
        margin-right: 16px;
    }
}
