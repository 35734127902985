@use '../../scss/_mixins.scss';
@use '../../scss/functions';
@import '../../scss/Variables.scss';

.dashboard { 
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 32px 24px; 
    background-color: white; 
    box-sizing: border-box;
    background: var(--white);
    .search-container{
        display: flex;
        justify-content: right;
        .search{
            width: fit-content;
        }
    }
    h1{
        font-family: "Roboto", sans-serif;
        font-size: functions.pxToRem(34);
        font-weight: 400;
        color: $color-font-default;
        margin: 16px 0 24px 0;
    }
    
    img { display: block; margin: 0 auto; @include mixins.adaptive-width(500); }
    .bold { font-weight: bold; }
    .links { display: flex;
        a { border: 1px solid black; font-weight: bold; padding: 8px 12px; margin-right: 12px; transition: background-color 0.25s; }
        a:hover { background-color: black; color: white; transition: background-color 0.5s; }
    }
}