@use '../../scss/functions';
@use '../../scss/_mixins.scss';
@import '../../scss/Variables.scss';

.ds-tabs {
    [role="tablist"] {
        display: flex;
    }

    &--rectangular {
        [role="tablist"] {
            [role="tab"] {
                width: 100%;
                padding: 16px;
                border-bottom: 1px solid var(--grey-25);
                font-weight: 400;
                color: var(--grey);
                font-size: 1rem;
                box-sizing: border-box;
                text-align: center;
                &[aria-selected="true"]{
                    color: var(--color-primary);
                    border-color: var(--color-primary);
                    border-width: 3px;
                    font-weight: 500;
                }
                &:hover{
                    background-color: var(--color-primary-light);
                }
            }
        }
    }

    &--round {
        background: none;
        [role="tablist"] {
            background: none;
            align-items: flex-end;
            [role="tab"] {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30%;
                height: 45px;
                padding: 0;
                border: none;
                background: white;
                border-radius: 10px 10px 0 0; 
                transition: all 0.2s ease-out;
                color: $color-undefined;
                border: 1px solid $color-dashboard-primary-light;
                border-bottom:none;
                &[aria-selected="true"], &:hover{
                    height: 55px;
                    font-size: 1.1em;
                    color: $color-font-default;
                    font-weight: 500;
                }
                &:first-child{
                    width: 60%;
                }
                &:nth-child(2){
                    margin:0 5% 0 auto;
                }
                span {
                    padding: 0 16px;
                    text-align: left;
                }
            }
        }
        [role="tabpanel"]{
            background-color: white;
            border-radius: 0 10px 10px 10px;
            border: 1px solid $color-dashboard-primary-light;
            border-top:none;
            @include mixins.default-box-shadow;
            height: calc(100% - (35px + 16px));
            padding: 16px;
        }
    }
}