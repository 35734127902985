@import "../../scss/Variables.scss";

.work {
    box-sizing: border-box;
    padding: 16px;
    max-width: 1032px;
    width: 100%;
    margin: 70px auto 118px auto;

    > header {
        margin-bottom: 64px;

        h1 {
            margin: 12px auto 6px auto;
            font-weight: 500;
        }
        .work__info {
            margin-top: 24px;
        }
    }

    .work-modes {
        margin-bottom: 32px;
    }

    @media screen and (max-width: 768px) {
        width: 378px;
        margin: 24px auto;

        > header {
            margin-bottom: 32px;

            h1 {
                margin-bottom: 16px;
            }
        }

        .work-modes {
            margin-bottom: 32px;
        }
    }
}
