.details {
    > summary {
        cursor: pointer;
        list-style: none;
        &::-webkit-details-marker {
            display: none;
        }

        > div {
            display: flex;
            gap: 12px;

            svg {
                fill: currentColor;
            }
        }
    }

    &[open] {
        > summary {
            margin-bottom: 12px;
        }

        .summary--hide-on-open {
            display: none;
        }
    }
    &:not([open]) .summary--hide-on-closed {
        display: none;
    }

    &.details--primary > summary > div {
        padding: 12px;
        border-radius: 4px;
        width: fit-content;
        background-color: var(--light-grey);
    }
    &.details--secondary .summary__title {
        text-decoration: underline;
    }
}
