@import "./Variables.scss";

@mixin focus {
    outline: 2px solid var(--black);
    border-radius: 4px;
}

@mixin rem($property, $value){
    #{$property}: ($value / 16) + rem;
}

// FONTS
@mixin font-size($size) {
    font-size: $size;
}

@mixin visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

@mixin width-height($size) {
    width: $size + px;
    height: $size + px;
}

@mixin adaptive-width($maxWidth) {
    width: 100%;
    max-width: $maxWidth + px;
}

@mixin material-icons {
    direction: ltr;
    display: inline-block;
    font-family: "Material Icons";
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
}

@mixin progression ($type){
    border-radius: 100%;
    @if $type == "improvement"{
        @include material-icons;
        content: "arrow_right_alt";
        background-color: #EBF9E5;
        color: $color-correct;
        transform: rotate(-45deg);
    }
    @if $type == "regression"{
        @include material-icons;
        content: "arrow_right_alt";
        background-color: #FFE5E5;
        color: $color-error;
        transform: rotate(45deg);
    }
    @if $type == "none"{
        display: flex;
        align-items: center;
        justify-content: center;
        content: "-";
        width: 24px;
        height: 24px;
        background-color: $background-color-secondary;
        color: $color-primary;
        font-size: 24px
    }
}

@mixin person {
    @include material-icons;
    content: "person";
    border-radius: 100%;
    background-color: $background-color-secondary;
    color: $color-dashboard-primary;
    font-size: 18px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(0);
}

@mixin default-box-shadow{
    box-shadow: 2px 12px 50px rgba($color: #000000, $alpha: 0.05);
}