
.ds-controlled-dialog {
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }

    padding: 30px 50px;
    padding-top: 60px;
    border-radius: 29px;
    display: flex;
    flex-direction: column;
    max-height: calc(90vh - 66px);
    width: 50vw;

    .ds-controlled-dialog__title {
        font-size: 1.125rem;
        margin-bottom: 30px;
        font-weight: 500;
    }

    .ds-controlled-dialog__content {
        flex: 1 1 0;
        overflow-y: auto;

        > p {
            font-size: 1rem;
            margin-bottom: 40px;

            &::first-letter {
                text-transform: capitalize;
            }
        }
    }

    .ds-controlled-dialog__buttons-container {
        // So that there is 40px of space between the description and the
        // children, and between the children and the buttons, but 70px between
        // the decsription and the buttons if there are no children, the top
        // margin is split with padding to that the margin collapse to the
        // right amount
        margin-top: 10px;
        padding-top: 30px;
        display: flex;
        flex-direction: row-reverse;
        gap: 8px;

        .ds-controlled-dialog__primary-btn,
        .ds-controlled-dialog__secondary-btn {
            padding: 15px 25px;
            display: block;
            text-transform: uppercase;
            border-radius: 100px;
            font-size: 0.875rem;
            font-weight: 700;
        }

        .ds-controlled-dialog__primary-btn {
            color: white;
            background-color: var(--primary-blue);
        }

        .ds-controlled-dialog__secondary-btn {
            color: #4e4e4e;
        }
    }

    &.ds-controlled-dialog--alert .ds-controlled-dialog__primary-btn {
        background-color: var(--color-incorrect);
    }
}
