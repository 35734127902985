@import "../../scss/Variables.scss";

.ds-custom-select {
    position: relative;
    width: max-content;
    isolation: isolate;
    border-radius: 10px;
    padding: 8px 5px;

    label {
        color: $color-primary;
        font-size: 0.9em;
    }

    .ds-custom-select__select-wrapper {
        position: relative;
        
        .ds-custom-select__native-select {
            position: absolute;
            height: 100%;
            width: 100%;
            opacity: 0;
            z-index: 1;
            &:focus + .ds-custom-select__visual-select {
                outline: 1px dotted #212121;
                outline: 1px auto -webkit-focus-ring-color;
                outline-offset: 2px;
            }
            &:hover {
                cursor: pointer;
            }
        }

        .ds-custom-select__visual-select__wrapper {
            display: flex;
            align-items: center;
            width: max-content;
            position: relative;
            overflow: hidden;
            pointer-events: none;
            z-index: 1;
            .option {
                margin-right: 4px;
            }
            .visual-select__drop-icon {
                pointer-events: none;
                justify-content: center;
                align-items: center;
                margin-left: auto;
            }
        }
    }

    &--inline {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 0;

        label {
            color: inherit;
        }

        .ds-custom-select__visual-select__wrapper {
            border-radius: 10px;
            background-color: var(--white);
            color: var(--black);
            padding: 5px 8px;
        }
    }
}
