// Custom select
.details-custom-select.filters {
    width: 270px;
    position: relative;
    background-color: #f4f4f4;
    margin: 0 0 24px 0;
    cursor: pointer;
    .filter-icon{
        width: 20px;
        height: 8px;
        border-radius: 5px;
        &.filter-icon-inProgress {
            border: 2px dashed #8ac0ec;
            background-color: #cfe0e9;
            box-sizing: border-box;
        }
        &.filter-icon-completed {
            background-color: #8ac0ec;
        }
    }
    > button {
        height: 42px;
        padding: 0 8px;
        position: absolute;
        right: -30px;
        top: 0;
        background-color: #f4f4f4;
        border-radius: 5px;
        border: 1px solid #333;
    }
    > .label {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 8px;
        background-color: #f4f4f4;
        border: 1px solid #333;
        border-radius: 5px;
        gap: 8px;
        span {
            &.icon {
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    .select {
        width: 100%;
        position: absolute;
        top: 35px;
        left: 0;
        z-index: 100;
        background-color: #f4f4f4;
        border: 1px solid #ccc;
        box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
        .option {
            display: flex;
            gap: 8px;
            align-items: center;
            padding: 8px;
            border-bottom: 1px solid #ccc;
            >*:first-child {
                width: 20px;
            }
            &:hover {
                background-color: white;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

// -----