.ds-removable-banner {
    display: flex;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
}

.ds-removable-banner--informational {
    background-color: var(--color-primary-light);
}

.ds-removable-banner__content {
    margin: 0 64px 0 8px;
}