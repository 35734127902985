$feedback-width--lg: 450px;
$feedback-width--md: 320px;
$feedback-width--sm: 100%;

.wizard-feedback-container {
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }

    display: flex;
    flex-direction: column;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.2s ease-out;

    @media screen and (min-width: 1240px) {
        width: var(--fb-container-width, $feedback-width--lg);
    }
    @media screen and (max-width: 1239px) {
        width: var(--fb-container-width, $feedback-width--lg);
    }
    width: var(--fb-container-width);

    &.--animate-feedback {
        opacity: 1;
        transform: translateX(0);
    }

    .wizard-feedback__content {
        flex: 1;
    }

    .wizard-feedback__navigation-button {
        margin-top: auto;

        > button {
            &:first-child {
                width: 100%;
            }
        }
    }

    .wizard-feedback__resize-bar {
        position: absolute;
        height: 100%;
        width: 24px;
        left: -24px;
    }
}

@media screen and (max-width: 600px) {
    .wizard-feedback-container {
        width: unset;
        transform: translateY(100%);

        &.--animate-feedback {
            transform: translateY(0);
        }

        .wizard-feedback__content {
            flex: 0 0 auto;
        }

        .wizard-feedback__navigation-button {
            z-index: 1;
            button {
                border-radius: 0;
            }
        }

        .wizard-feedback__resize-bar {
            display: none;
        }
    }
}
