@import "../../../scss/Variables.scss";

.slider-container {
    position: relative;
    overflow: hidden;
    
    .carousel-root {
        position: relative;
        .carousel {
            height: 100%;
            &.carousel-slider {
                overflow:unset;
                overflow-x: hidden;
                min-height: calc(100vh - 66px);
            }

            .control-arrow {
                height: 200px;
                width: 95px;
                top: 50%;
                bottom: auto;
                margin-top: auto;
                background-color: $color-primary;
                &.control-next {
                    border-radius: 500px 0 0 500px;
                    transform: translate(30px, -50%);
                    &:before {
                    }
                }
                &.control-prev {
                    border-radius: 0 500px 500px 0;
                    transform: translate(-30px, -50%);
                    &:before {
                    }
                }
                &:before {
                }
            }
            .slider-wrapper {
                min-height: calc(100vh - 66px);
                ul.slider {
                    min-height: calc(100vh - 66px);
                    li.slide {
                        padding: 0 95px;
                        text-align: left;
                        article {
                            min-height: calc(100vh - 66px);
                            padding-bottom: 56px;
                            position: relative;
                            h2,
                            p {
                                font-weight: 300;
                            }
                            h2 {
                                font-family: "Roboto", sans-serif;
                                padding: 8% 0 0 0;
                                font-size: 3.4em;
                                line-height: 1em;
                            }
                            p {
                                margin: 3% 0 5% 0;
                                font-size: 1.3737rem;
                                line-height: 1.4em;
                                &.main-decsr {
                                    font-size: 1.7em;
                                }
                            }
                            .background-icon {
                                position: absolute;
                                right: 16px;
                                bottom: 16px;
                                width: 20vw;
                                height: 40vh;
                                display: flex;
                                justify-content: flex-end;
                                align-items: flex-end;
                                
                                img {
                                    object-fit: contain;
                                }

                                &.background-icon--svg img {
                                    height: 100%;
                                    width: auto;
                                    max-width: 100%;
                                }
                            }

                            .slide-btn {
                                text-transform: none;
                                font-weight: 700;
                            }

                            &.intro {
                                h2 {
                                    font-family: "Roboto", sans-serif;
                                    font-size: 3.4em;
                                }
                                .btn {
                                    margin: 0;
                                }
                                .background-icon {
                                    height: 80vh;
                                }
                            }
                            &.list {
                                ul {
                                    width: fit-content;
                                    list-style-type: disc;
                                    margin: 0 auto 5% auto;
                                    &.hasColumns {
                                        column-count: 2;
                                        column-gap: 64px;
                                        li, li > span {
                                            break-inside: avoid;
                                        }
                                    }
                                    li {
                                        list-style-type: disc;
                                        margin: 0 0 16px 0;
                                        font-weight: 300;
                                        font-size: 1.125rem;
                                        align-items: flex-start;

                                        img {
                                            width: auto;
                                            margin: 3px 8px 0 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .control-dots {
            li {
                width: 24px;
                height: 24px;
                &.dot {
                    background: none;
                    background-color: $color-primary;
                    box-shadow: none;
                }
            }
        }
    }

    .background {
        position: absolute;
        width: 50%;
        img {
            display: block;
            width: 100%;
            height: auto;
            object-fit: contain;
        }
        &.left {
            left: 0;
            bottom: 0;
        }
        &.right {
            right: 0;
            top: 0;
        }
        &.logos {
            width: 280px;
            bottom: 24px;
            left: 24px;
        }
    }
}
@media (max-width: 1024px) {
    .slider-container {
        font-size: 0.8em;
    }
}
@media (max-width: 980px) {
    .slider-container {
        .carousel-root {
            .carousel {
                .slider-wrapper {
                    ul.slider {
                        align-items: center;
                        li.slide {
                            height: auto;
                            article {
                                height: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .slider-container {
        font-size: 0.6em;
        .carousel-root {
            .carousel {
                .slider-wrapper {
                    ul.slider {
                        align-items: start;
                        li.slide {
                            padding: 0 5%;
                            article {
                                &.intro {
                                    .background-icon {
                                        max-width: 150px;
                                        &.background-icon--svg {
                                            width: 150px;
                                        }
                                    }
                                }
                                .background-icon {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            .control-arrow {
                display: none;
            }
        }
        .background {
            display: none;
        }
    }
}
