.resize-bar {
    background: transparent;
    cursor: col-resize;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 2px;
        opacity: 0;
        transition: opacity 100ms;
        background: var(--color-primary);
    }

    &:hover,
    &.--hover {
        &::after {
            opacity: 1;
        }
    }
}
