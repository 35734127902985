@import "../../scss/Variables.scss";

$blue: #0d63a5;

.error-container {
    display:flex; max-width: 740px; width: 100%; height: 100%; padding: 32px; align-self: center; align-items: center;
    .content {
        width: 60%;
        h1{
            font-size: 32px; margin: 0 0 32px 0 ;
        }
        p{
            margin: 0 0 32px 0;
        }
        a{
            display: block; max-width: 200px; width: 100%; margin: 0 0 32px 0; padding: 16px; background-color: $blue; border-radius: 10px; color: white; text-align: center; font-weight: 600; border: 2px solid transparent;
            &:hover{
                background-color: white; color: $blue; border-color: $blue;
            }
        }
        .select-container{
            display: flex; align-items: center; width: max-content; background-color: white; padding: 8px 16px; border-radius: 5px; box-shadow: 0 0 4px rgba($color: #000000, $alpha: 0.2);
            select{
                background: none; border: none; margin: 0 0 0 8px;
            }
        }
    }
    .img{
        width: 40%;
        img{
            display:block ;width: 100%; height: auto;
        }
    }
}
