.work-in-progress-home{
    width:100%;
    box-sizing: border-box; 
    max-width: 1032px;
    padding: 0 20px;
    margin: 70px auto 0 auto;
    h1{
        margin: 0 0 24px 0;
    }
    h2{
        margin: 0 0 52px 0;
        font-weight: 400;
        font-size: 20px;
    }
    .work-in-progress-home__activities{
        > article{
            margin: 0 0 12px 0;
        }
    }
}