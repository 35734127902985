.ds-tooltip {
    position: absolute;

    z-index: 100;
    background: rgba(0,0,0,0.70);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px;

    max-width: 150px;

}

.ds-tooltip__container {
    position: relative;
}
