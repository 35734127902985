.ds-chip {
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 0.875rem;
    padding: 0 12px;
    width: max-content;
    min-height: 24px;
    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--font-black);
    background-color: var(--light-grey);
    line-height: 1;

    .ds-chip__set-type-icon {
        margin-left: -2px;
        margin-right: -2px;
    }

    .ds-chip__label {
        margin-right: 10px;
    }

    &.ds-chip--small {
        padding: 4px;
        height: 24px;
        gap: 4px;
    }
    &.ds-chip--medium .ds-chip__input {
        font-weight: 700;
    }
    &.ds-chip--big {
        font-size: 1rem;
    }

    &.ds-chip--conf {
        background-color: var(--alert-conf-chip-light);
    }
    &.ds-chip--inf {
        background-color: var(--alert-inf-chip-light);

        &.ds-chip--small .ds-chip__input {
            color: var(--alert-inf-dark);
        }
    }
    &.ds-chip--sup {
        background-color: var(--alert-sup-chip-light);

        &.ds-chip--small .ds-chip__input {
            color: var(--alert-sup-dark);
        }
    }
    &.ds-chip--correct {
        background-color: var(--true-light);
        color: var(--true);
    }
    &.ds-chip--incorrect {
        background-color: var(--false-light);
        color: var(--false);
    }

    &.ds-chip--dark-bg {
        color: var(--white);
        background-color: var(--white-15);

        &.ds-chip--conf {
            background-color: var(--alert-conf-chip-dark);
        }
        &.ds-chip--inf {
            background-color: var(--alert-inf-chip-dark);
        }
        &.ds-chip--sup {
            background-color: var(--alert-sup-chip-dark);
        }
        &.ds-chip--correct {
            background-color: var(--true);
        }
        &.ds-chip--incorrect {
            background-color: var(--false);
        }
    }
}
