@import "../../../scss/Variables.scss";

.search {
    padding: 2px 4px 2px 8px;
    position: relative;
    .bar {
        display: flex;
        align-items: center;
        border-radius: 10px;
        background-color: white;
        span {
            margin: 0 8px 0 12px;
            font-size: 18px;
        }
        input {
            width: 100%;
            margin: 7px 8px 7px 0;
            padding: 8px;
            border: none;
            background: none;
            border-radius: 10px;
        }
    }
    .list {
        display: none;
        width: 199px;
        padding: 16px;
        position: absolute;
        left: 8px;
        top: 41px;
        z-index: 1;
        background: white;
        border-radius: 0 0 10px 10px;
        box-shadow: 0 6px 4px rgba(0, 0, 0, 0.2);
        &.show {
            display: block;
        }
        .simplebar-wrapper {
            max-height: 300px;
            ul {
                /* overflow-y: auto; */
                li {
                    display: flex;
                    align-items: center;
                    padding: 4px 0;
                    font-size: 0.9em;
                    cursor: pointer;
                    &:hover {
                        color: $color-primary;
                    }
                    .icon {
                        background-color: #f1f1f1;
                        border-radius: 100%;
                        padding: 4px;
                        margin-right: 8px;
                    }
                    .name {
                    }
                }
            }
        }
    }
}
