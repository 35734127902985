@import "../../../../../design-system-components/LayerModal/var.scss";

.progression-graph-legend {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    row-gap: 12px;
    position: relative;

    // Little trick to hide the bottom border of sub legend
    &:after {
        content: "";
        border-bottom: solid $ds-lm--main-layer-bg-color 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .progression-graph-legend__col {
        margin-left: 32px;
        border-bottom: solid 1px var(--medium-grey);

        > div {
            display: flex;
            align-items: center;
            margin: 0 0 10px 0;

            .progression-graph-legend__color-sample {
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;

                > span {
                    width: 16px;
                    height: 16px;
                    border: 1px solid;
                    border-radius: 2px;
                    box-sizing: border-box;
                }

                &.progression-graph-legend__color-sample--expected > span {
                    border-color: var(--alert-conf-default);
                    background-color: var(--alert-conf-bubble);
                }

                &.progression-graph-legend__color-sample--success-level > span {
                    border-width: 2px;
                    border-color: var(--alert-conf-chip-dark);
                    background-color: var(--white);
                }
            }

            > span {
                &:first-child {
                    margin-right: 8px;
                }

                &:last-child {
                    color: var(--black);
                }
            }

            .progression-graph-legend__tooltip {
                margin-left: 5px;
            }
        }
    }
}
