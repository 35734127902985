.progress-bar-with-label {
    display: flex;
    justify-content: space-between;
    isolation: isolate;
    position: relative;
    min-height: 68px;
    align-items: center;
    border-radius: 21px;
    overflow: hidden;
    padding: 0 21px;
    font-size: 1.125rem;

    .progress-bar__background {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0.5;
        height: 100%;
        width: 100%;
    }

    .progress-bar {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        height: 100%;
        border-radius: 21px;
    }

    &.--no-progress {
        color: rgba(44, 62, 80, 0.53);
    }
}
