.classroom-creator{
    width: 100%;

    &__title {
        padding-inline: 24px;
        padding-top: 16px;
    }

    .classroom-creator__name {
        padding: 24px;
    
        label,
        input {
            display: block;
        }
    
        label {
            margin-bottom: 12px;
        }
        .classroom-creator__name__message{
            margin: 24px 0 0 0;
        }
    }
    
    .classroom-creator__actions {
        display: flex;
        justify-content: flex-end;
        margin: 0 0 0 0;
        padding: 16px 24px;
        border-top: 1px solid var(--light-grey);
        gap: 32px;
    }
}

@media (max-width: 50rem) {
    .classroom-creator {
        height: 100%;
        form{
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        .classroom-creator__actions {
            padding: 0;
            margin: 0;
            margin-top: auto;
            button {
                width: 100%;
                border-radius: 0;
                &.classroom-creator__actions--cancel,
                &.classroom-creator__actions--previous {
                    display: none;
                }
            }
        }
    }
}