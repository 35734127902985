@use "./var.scss";
@use "../../scss/functions" as fn;

.layer-modal {
    width: auto;
    max-width: min(80vw, 1440px);
    margin: 0;
    padding: 0;
    background-color: var(--layout-darkblue);
    border-radius: 30px;
    overflow: hidden;

    .layer-modal__inner {
        max-height: calc(100vh - 48px);

        .layer-modal__backdrop {
            color: var(--white);
            padding: 32px;
        }

        .layer-modal__main {
            background-color: var.$ds-lm--main-layer-bg-color;
            border-radius: 30px 30px 0px 0px;
            padding: var.$main-layer-padding;

            .layer-modal-main__title {
                margin-top: - var.$main-layer-padding;
                margin-inline: - var.$main-layer-padding;
                margin-bottom: var.$main-layer-padding;
                border-radius: 30px 30px 0px 0px;
                padding: var.$main-layer-padding;
                background-color: var.$ds-lm--main-layer-title-bg-color;
                font-size: fn.pxToRem(24);
                font-weight: 400;
            }
        }
    }

    &.layer-modal--prevent-scroll {
        .layer-modal__main {
            height: 0;
        }
    }

    &:not(.layer-modal--prevent-scroll) .layer-modal__inner {
        overflow-y: auto;
    }
}

@media screen and (max-width: 768px) {
    .layer-modal {
        max-width: 90vw;
    }
}

@media screen and (max-width: var.$ds-lm--breakpoint-fullsize-max-width),
    screen and (max-height: var.$ds-lm--breakpoint-fullsize-max-height) {
    .layer-modal {
        min-width: auto;
        max-width: 100vw;
        width: 100vw;
        height: 100%;
        border-radius: 0;
        top: 0;
        left: 0;
        transform: none;

        &__container {
            height: 100%;
        }

        .layer-modal__inner {
            max-height: 100%;
            height: 100%;
            width: 100%;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;

            .layer-modal__main {
                flex: 1;
            }
        }
    }
}
