@use "../Dialog/Dialog" as dialog;

.ds-modal__background {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.ds-modal {
    box-sizing: border-box;
    position: relative;
    padding: 0;
    margin: 0;
    overflow: auto;
    max-height: 100%;
}
