.student-creator-list{
    display: flex;
    flex-direction: column;
    min-height: 200px;
    .student-creator-list__count{
        padding: 0 24px 12px 24px;
        color: var(--grey);
    }
    .student-creator-list__list{
        width: 100%;
        max-height: 350px;
        overflow: auto;
        &.student-creator-list__list--empty{
            display: flex;
            flex-direction: column;
            flex: 1;
            align-items: center;
            justify-content: center;
            background-color: var(--light-grey);
        }
        .student-creator-list__item{
            display: flex;
            align-items: center;
            padding: 0 24px;
            margin: 8px 0;
            .student-creator-list__item__name{
                width: 100%;
            }
            .student-creator-list__item__actions{
                justify-self: flex-end;
                button{
                    height: 42px;
                    width: 42px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    isolation: isolate;
                    > *{
                        z-index: 2;
                    }
                    &:hover{
                        &:after{
                            z-index: 1;
                            display: block;
                            content:"";
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background-color: var(--left-plot-filled);
                            border-radius: 100%;
                        }
                    }
                }
            }
        }
    }
}