.module-map {
    display: flex;
    align-items: center;

    &__modal {
        width: fit-content;
        max-width: 90vw;
    }

    &__modal-title {
        display: flex;
        align-items: center;
        padding: 16px;
        padding-inline-start: 24px;

        h2 {
            font-size: 1.1em;
            font-weight: 500;
            font-family: "Roboto";
        }

        > *:not(:last-child) {
            margin-right: 12px;
        }
    }
}
