.pwd-criteria {
  border-radius: 2px;
  border: 1px solid #c2c8d0;
  padding: 16px;
  margin-top: 12px;

  .pwd-criteria__criterion {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-top: 1rem;

      &.pwd-criteria__criterion--compliant {
          color: #858585;
          
          .pwd-criteria__criterion-icon {
              color: var(--true);
          }
      }

      &.pwd-criteria__criterion--not-compliant {
          color: #2d333a;
          
          .pwd-criteria__criterion-icon {
              color: var(--false);
          }
      }
  }

  &[role="alert"] {
    border-color: var(--false);
    color: var(--false);
  }
}