.student-creator{
    &__title {
        padding: 24px;
    }

    form{
        padding: 24px;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        > div{
            width: calc(50% - 8px);
            label{
                display: block;
                margin-bottom: 12px;
                color: var(--grey);
            }
            &.student-creator__lastname{
                margin-left: auto;
            }
        }
        .student-creator__submit{
           width: 100%; 
        }
    }
    .student-creator__actions {
        display: flex;
        justify-content: flex-end;
        margin: 0 0 0 0;
        padding: 16px 24px;
        border-top: 1px solid var(--light-grey);
        button:first-child{
            margin-right: auto;
        }
    }
}

.student-creator--errorModal{
    ul{
        margin: 24px 0 0 0;
        li{
            margin-left: 24px;
            list-style: square;
            margin-bottom: 12px;
            font-weight: 500;
        }
    }
}

@media (max-width: 50rem) {
    .student-creator {
        height: 100%;
        display: flex;
        flex-direction: column;
        .student-creator-list{
            flex: 1;
        }
        .student-creator__actions {
            padding: 0;
            margin: 0;
            button {
                width: 100%;
                border-radius: 0;
                &.student-creator__actions--cancel,
                &.student-creator__actions--previous {
                    display: none;
                }
            }
        }
    }
}