@use "../../../../scss/functions" as fn;
@use "../../vars";

.password-change-form {
    @include vars.auth-card;

    .password-change-form__label {
        color: var(--grey-dark);
        display: block;
        margin-top: 32px;
        margin-bottom: 8px;

        &.password-change-form__label--inactive {
            margin-bottom: 16px;
        }
    }

    .password-change-form__email {
        display: block;
        width: 100%;
        font-size: 1rem;
        border: none;
        color: inherit;
    }

    button[type="submit"] {
        width: 100%;
        margin-top: 42px;
    }
}

@media screen and (min-width: fn.pxToRem(768)) {
    .password-change-form .password-change-form__label {
        margin-top: 52px;
    }
}
